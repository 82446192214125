import React from 'react'
import PropTypes from 'prop-types'
import '../ExperimentUI.css'
import Tooltip from '@material-ui/core/Tooltip'

/**
 * A sub control of toolbar that exposes controls to set zoom level with slider and buttons
 */
class ViewingTools extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      fullscreen: false
    }
  }

  /**
   * default react component render function implementation
   */
  render () {
    return (
      <React.Fragment>
        <div className="zoomer">
          <div className="zoomer-centered">
            <label className="full-screen-only">Zoom</label>
            <input type="range" min="5" max="100" value={this.props.zoomValue * 100}
                   onChange={(e) => this.updateZoom(e.target.value)} title="Zoom"
                   onMouseDown={this.props.sliderStart}
                   onMouseUp={this.props.sliderEnd}/>
            <label className="zoom-display">{Math.round(this.props.zoomValue * 100)}%</label>
          </div>
        </div>
        <div className="buttons">
          <Tooltip title="Fit image to height">
            <button className="fit-height" onClick={this.props.fitAll}/>
          </Tooltip>
          <Tooltip title="Fit image to width">
            <button className="fit-width" onClick={this.props.fitHeight}/>
          </Tooltip>
          <Tooltip title={this.state.fullscreen ? 'Exit fullscreen mode' : 'Enter fullscreen mode'}>
            <button className="fullscreen"
                    onClick={() => {
                      this.setState({ fullscreen: !this.state.fullscreen })
                      this.props.fullscreen()
                    }}/>
          </Tooltip>
        </div>
      </React.Fragment>
    )
  }

  /**
   * Handler for the onChange event of the slider
   * also calls the method from props that in turn sets
   * the image in view's zoom level as selected from the slider
   * @param {float} value the zoom value selected from the slider
   */
  updateZoom (value) {
    this.props.setZoom(value / 100, 'slider')
  }
}

ViewingTools.propTypes = {
  zoomValue: PropTypes.number.isRequired,
  sliderStart: PropTypes.func.isRequired,
  sliderEnd: PropTypes.func.isRequired,
  fitAll: PropTypes.func.isRequired,
  fitHeight: PropTypes.func.isRequired,
  fullscreen: PropTypes.func.isRequired,
  setZoom: PropTypes.func.isRequired
}

export default ViewingTools
