import React from 'react'
import ViewingTools from './ViewingTools'
import PropTypes from 'prop-types'
import '../ExperimentUI.css'

/**
 * A component of the experiment UI that contains viewing controls
 */
class Toolbar extends React.Component {
  /**
   * default react component render function implementation
   */
  render () {
    const { fitAll, fitHeight, setZoom, fullscreen, sliderStart, sliderEnd } = this.props

    return (
      <div className="topnav">
        <div className="topnav-row">
          <div className="topnav-info">
            <span className="app-name">Zovi</span>
          </div>

          <ViewingTools
            zoomValue={this.props.zoomValue}
            fitAll={fitAll}
            fitHeight={fitHeight}
            fullscreen={fullscreen}
            setZoom={setZoom}
            sliderStart={sliderStart}
            sliderEnd={sliderEnd}/>
        </div>
      </div>
    )
  }
}

Toolbar.propTypes = {
  fitAll: PropTypes.func.isRequired,
  fitHeight: PropTypes.func.isRequired,
  setZoom: PropTypes.func.isRequired,
  fullscreen: PropTypes.func.isRequired,
  sliderStart: PropTypes.func.isRequired,
  sliderEnd: PropTypes.func.isRequired,
  zoomValue: PropTypes.number.isRequired
}

export default Toolbar
