import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

/**
 * User will be directed to this page if not using desktop
 */
class DeviceInfoPage extends React.Component {
  /**
   * default react component render function implementation
   */
  constructor (props) {
    super(props)

    this.state = {
      config: this.props.location.state.data
    }
  }

  render () {
    if (this.state.config) {
      return (
      <React.Fragment>
        <Helmet bodyAttributes={{ class: 'start-ui-bg' }}/>
        <div className="center" style={{ color: 'white' }}>
          <h1>Welcome to ZOVI!</h1>
          <h3>Image viewer and annotation tool</h3>
          To ensure the accuracy of the experimental results your screen resolution needs to be larger than {this.state.config.settings.minAllowedNativeScreenSize.width} x {this.state.config.settings.minAllowedNativeScreenSize.height} pixels. <br />
          Please open this page on a desktop or a laptop connected to a large enough screen.
          <h4>Thank you!</h4>
        </div>
      </React.Fragment>
      )
    } else {
      return null
    }
  }
}

DeviceInfoPage.propTypes = {
  location: PropTypes.object.isRequired
}

export default DeviceInfoPage
