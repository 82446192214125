import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Viewer from 'viewerjs'
import { Constants } from '../constants'
import { v4 as uuidv4 } from 'uuid'
import Modal from '@material-ui/core/Modal'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AccuracyDisplay from './AccuracyDisplay'
import ImageNotFound from '../images/image-not-found.png'
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/Warning'
import PropTypes from 'prop-types'

require('viewerjs/dist/viewer.min.css')

/**
 * The main component of the Experiment UI that displays the image and allows navigation and control
 */
class ImageViewerComponent extends PureComponent {
  /**
   * default react component contructor function implementation
   */
  constructor (props) {
    super(props)
    this.state = {
      nextDisabled: true, // the enabled or disabled state of the next button
      nextVisible: true, // the visibility of the next button
      prevDisabled: false, // the enabled or disabled state of the previous button
      prevVisible: false, // the visibility of the previous button
      canSubmit: false, // to show the button if experiment can be submitted, (i.e. the user has reached the last image in the viewer)
      navigated: true, // to use to check if an image has been rendered first time before or after navigating
      canContinue: false, // can user continue from last session
      lastSessionIndex: 0, // the last image index from last session
      showMessage: { show: false, message: '' }, // should show when submit experiment is unsuccessful
      continueDisabled: true, // disable continue button until image is loaded
      showImageInfo: false,
      playAnimation: false,
      preventZoomMessage: false, // if true, show message to reset zoom
      showTask: false,
      trainingAccuracy: false,
      trainingComplete: false,
      connectionLostMessage: false,
      connectionWarning: false,
      fixWindowSizeDialog: false,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight
    }

    this.config = this.props.config // the controls configuration to allow custom mouse and keyboard events and app defaults
    this.activityLog = {}
    this.resettingToken = false
    this.accuracy = 0

    // Load data from last session
    const _lastSessionData = localStorage.getItem(Constants.APP_NAME + '_' + this.props.urlToken)
    this.lastSessionData = null
    if (_lastSessionData) {
      this.lastSessionData = JSON.parse(_lastSessionData)
      this.state.canContinue = true
      this.state.lastSessionIndex = this.lastSessionData.lastIndex
      this.dppx = parseFloat(this.lastSessionData.dppx)
      this.uniqueHash = this.lastSessionData.uniqueHash
      if (this.lastSessionData.activityRecord) {
        Object.keys(this.lastSessionData.activityRecord).forEach(k => {
          this.lastSessionData.activityRecord[k].forEach((entry, index) => {
            if (!('endTime' in entry)) {
              this.lastSessionData.activityRecord[k][index].endTime = null
            }
          })
        })
      }
      this.activityLog = this.lastSessionData.activityRecord
      this.accuracy = this.lastSessionData.accuracy
    }

    this.dppx = this.dppx || localStorage.getItem('dppx') || window.devicePixelRatio
    this.uniqueHash = this.uniqueHash || uuidv4()

    this.images = [] // Contains app specific data for all images {image, lastZoom, lastPosition, loaded, answered}
    this.filenames = this.props.imageList // The list of image file names to add to the viewer
    this.randomize = this.config.settings.randomize || false
    this.loadedImages = 0 // A counter that will be updated to hold the count for all images loaded
    this.load_order = [...Object.keys(this.filenames).map((fn) => parseInt(fn))] // The order in which the images load
    this.attempts = 0

    if (this.lastSessionData) {
      this.load_order = this.lastSessionData.displayOrder
    } else {
      if (this.randomize) {
        this.shuffle(this.load_order)
      }

      const isTrainingImage = (index) => {
        return this.filenames[index] instanceof Object &&
          (('type' in this.filenames[index] && this.filenames[index].type === 'training') ||
            (!('type' in this.filenames[index]) && 'optimalZoom' in this.filenames[index]))
      }
      // load training images first
      this.load_order.sort((a, b) => {
        if (isTrainingImage(a) && isTrainingImage(b)) {
          return 0
        } else if (isTrainingImage(a)) {
          return -1
        } else if (isTrainingImage(b)) {
          return 1
        } else {
          return 0
        }
      })
    }

    this.displayOrder = [...this.load_order]

    if (this.lastSessionData && Object.keys(this.lastSessionData.experimentData).length === this.filenames.length) {
      this.state.canSubmit = true
    }

    // check if user completed training in the last session
    if (this.lastSessionData) {
      let trainingComplete = true
      this.displayOrder.forEach(i => {
        if (!(this.filenames[i] instanceof Object)) {
          return
        }

        const img = this.filenames[i]
        if (('type' in img && img.type === 'training') || (!('type' in img) && 'optimalZoom' in img)) {
          if (!(i in this.lastSessionData.experimentData)) {
            trainingComplete = false
          }
        }
      })
      this.state.trainingComplete = trainingComplete
    }

    this._pictures = React.createRef() // A pointer to the image cache DOM element

    // Expose functions for other components to access and execute
    this.addImage = this.addImage.bind(this)
    this.fitImageAll = this.fitImageAll.bind(this)
    this.fitImageWidth = this.fitImageWidth.bind(this)

    const debounceResize = this.debounce(this.performChecks, 1000)
    window.addEventListener('resize', () => {
      debounceResize()
    })
  }

  /**
   * default react component render function implementation
   */
  render () {
    return (
      <React.Fragment>
        <div className={"zoom-viewer" + (this.state.navigated ? " viewer-loading hide-canvas" : " show-canvas")}>
          <div style={{ position: "absolute", zIndex: -1 }} ref={this._pictures}>
          </div>
          <div>
            {this.state.prevVisible
              ? <Tooltip title={this.state.prevDisabled ? "Loading previous image" : "View previous image"}>
                <span className="previous-btn">
                  <button
                    className="previous round"
                    onClick={() => this.previousImage()}
                    disabled={this.state.prevDisabled}>
                    <div className="loader"/>
                    &#8249;
                  </button>
                </span>
              </Tooltip>
              : null}
            {this.state.nextVisible
              ? <Tooltip title={this.state.nextDisabled ? "Loading next image" : "View next image"}>
                <span className="next-btn">
                  <button className="next round"
                          onClick={() => this.nextImage()}
                          disabled={this.state.nextDisabled}>
                    <div className="loader"/>
                    &#8250;
                  </button>
                </span>
              </Tooltip>
              : null}
            {this.state.connectionWarning
              ? <span className="warning-icon">
             <Tooltip
               title={this.config.settings.warn_after_image_load_attempts_tooltip || "Slow connection"}><WarningIcon
               fontSize="small" style={{ color: "#ffcc00" }}/></Tooltip>
          </span>
              : null}
            {this.state.canContinue && this.state.lastSessionIndex > 0
              ? <Link className="submit-btn" to="/" onClick={(e) => this.continueLastSession(e)}
                    disabled={this.state.continueDisabled}>
                {this.state.continueDisabled ? <div className="loader"></div> : null}
                Continue from last session
              </Link>
              : this.state.canSubmit
                ? <Link className="submit-btn" to="/submit" onClick={(e) => this.checkExperimentComplete(e)}>Submit
                  Experiment</Link>
                : null
            }

            {this.config.settings.show_accuracy
              ? <AccuracyDisplay
                config={this.config}
                accuracy={this.accuracy}
                showBar={this.accuracyBarVisible()}
                showCompleteIcon={this.state.trainingComplete}
                accuracyInfo={
                  this.accuracyBarVisible()
                    ? (this.state.trainingAccuracy ? "Training accuracy" : "Test accuracy")
                    : (this.state.trainingComplete &&
                    Object.values(this.images).filter(img => img.accuracyEvaluated && img.type === "training").length
                        ? "Training complete"
                        : "")
                }
              />
              : null
            }

            {this.viewer && this.viewer.length
              ? <React.Fragment>
                <div onClick={() => this.setState({ showImageInfo: !this.state.showImageInfo })}
                     className="image-info-btn">i
                </div>
                {this.state.showImageInfo && this.images[this.viewer.index].loaded
                  ? <div className="image-info">{this.viewer.image.alt}<br/>Resolution
                    ( {this.viewer.image.naturalWidth} x {this.viewer.image.naturalHeight} )</div>
                  : null
                }
              </React.Fragment>
              : null}

            <div className="alert-container">
              <div className="alert" style={this.state.showMessage.show ? { opacity: "0.9" } : { opacity: "0" }}>
                <span className="closebtn" onClick={() => {
                  this.setState({ showMessage: { show: false, message: this.state.showMessage.message } })
                }}>&times;</span>
                {this.state.showMessage.message}
              </div>
            </div>
          </div>

          {this.viewer && this.viewer.length && this.images[this.viewer.index].task
            ? <div className="task-info" onClick={() => this.showTaskMessage()}>
              <AssignmentIcon className="task-info-icon" fontSize="small"
                              style={{ cursor: "pointer", borderRadius: this.state.showTask ? "5px 0 0 5px" : "5px" }}/>
              <div className={this.state.showTask ? "task-info-text" : "task-info-text hide"}>
                <span>{this.images[this.viewer.index].task}</span></div>
            </div>
            : null
          }
          <div className="center">
            <Tooltip title="Displaying image"><input type="text" className="index-display"
                                                     value={(this.viewer ? this.viewer.index + 1 : 1) + " of " + this.filenames.length}
                                                     readOnly/></Tooltip>
          </div>
        </div>

        {this.state.playAnimation
          ? <Modal
            open={true}
          >
            <div className="optimal-zoom-alert-container blink">
              <div className="optimal-zoom-alert-triangle"/>
              <div className="optimal-zoom-alert" style={{ opacity: "0.9" }}>
                <div
  dangerouslySetInnerHTML={{ __html: this.formattedString(this.images[this.viewer.index].type === "training" ? this.config.settings.training_image_prompt : this.config.settings.test_image_prompt) }}/>
              </div>
            </div>
          </Modal>
          : null
        }

        {this.state.connectionLostMessage
          ? <div
            style={{
              zIndex: 21000,
              backdropFilter: "blur(2px)",
              position: "fixed",
              right: 0,
              bottom: 0,
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)"
            }}
          >
            <div className="center">
              <div style={{ color: "white" }}>
                <p>
                  <WarningIcon fontSize="small" style={{ color: "#ffcc00", verticalAlign: "middle", marginRight: 10 }}/>
                  {this.config.settings.max_image_load_attempts_message ||
                  "Unstable connection! Please check your connection to resume experiment."}
                </p>
                <div style={{ textAlign: "center" }}>
                  <a className="green-button" href="/experiment" onClick={(e) => this.resumeImageLoader(e)}>Resume</a>
                </div>
              </div>
            </div>
          </div>
          : null
        }

        {this.state.preventZoomMessage
          ? <Modal
            open={true}
            style={{ zIndex: 21000, backdropFilter: "blur(2px)" }}
          >
            <div className="center">
              <div style={{ color: "white" }}>
                Please reset browser zoom to continue to experiment.<br/>
                You can do this using the following keyboard shortcut:<br/>
                <kbd>Command</kbd>+<kbd>0</kbd> on Mac<br/>
                <kbd>Control</kbd>+<kbd>0</kbd> on Windows/Linux
              </div>
            </div>
          </Modal>
          : null
        }
        {this.state.fixWindowSizeDialog ? this.fixWindowSizeDialog() : null}
      </React.Fragment>
    )
  }

  fixWindowSizeDialog () {
    return (
        <Modal
          open={this.state.fixWindowSizeDialog}
          style={{ zIndex: 21000, backdropFilter: "blur(2px)" }}
        >
          <div className="center">
            <div style={{ color: "white" }}>
              Your browser window size is too small ({this.state.innerWidth} x {this.state.innerHeight} px). <br />
              Please maximise your browser window or <br />
              enter <button onClick={() => {
              this.fullscreenMode()
            }}>Full screen mode</button> to continue.
            </div>
          </div>
        </Modal>
    )
  }

  performChecks () {
    if (this.config && this.config.settings) {
      const _devicePixelRatio = window.devicePixelRatio || 1
      const settings = this.config.settings
      const nativeWindowWidth = (window.innerWidth * _devicePixelRatio)
      const nativeWindowHeight = (window.innerHeight * _devicePixelRatio)
      // Check 2
      if (nativeWindowWidth < settings.minAllowedNativeScreenSize.width || nativeWindowHeight < settings.minAllowedNativeScreenSize.height) {
        this.setState({ fixWindowSizeDialog: true })
      } else {
        this.setState({ fixWindowSizeDialog: false })
      }
    }
  }

  debounce (fn, ms) {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    }
  }

  /**
   * default react component componentDidMount function implementation
   */
  componentDidMount () {
    this.zoom_type = Constants.VIEWER_ZOOM // If the zoom was set from the viewer or the slider

    // Performing window size checks
    this.performChecks()

    // Setting default options for the viewer
    const options = {
      ...Constants.VIEWER_OPTIONS,
      minZoomRatio: this.config.defaults.zoom.min / this.dppx,
      maxZoomRatio: this.config.defaults.zoom.max / this.dppx,
      zoomRatio: this.config.defaults.zoom.step,
      zoomed: (e) => {
        // When viewer is zoomed we want to inform other controls of the new zoom value
        if (this.zoom_type !== Constants.SLIDER_ZOOM) {
          this.setZoomLevel(e.detail.ratio, 'viewer')
        }
      },
      ready: () => {
        // When the viewer is ready we want to apply our custom mouse controls from the config
        Object.keys(this.config.controls).forEach((control) => {
          if (!('mouse' in this.config.controls[control])) {
            return
          }

          const mouseMappings = this.config.controls[control].mouse.mappings
          this.addMouseAction(mouseMappings, control)
        })
      }
    }

    // initializing the viewer
    this.viewer = new Viewer(this._pictures.current, options)
    this.viewer.view(0)
    this.setState({ navigated: true })

    // Overriding view function for activity record
    Viewer.prototype.original_view_fn = Viewer.prototype.view
    Viewer.prototype.view = (index) => {
      if (this.activityRecord) this.activityRecord.endTime = Date.now()
      this.saveExperimentData()

      window.performance.clearMarks()
      window.performance.clearMeasures()

      this.activityRecord = {
        startTime: Date.now(),
        mouseDuration: 0,
        keyboardDuration: 0,
        sliderDuration: 0,
        panDuration: 0,
        zoomChange: 0,
        viewableArea: [],
        viewablePort: []
      }

      this.viewer.original_view_fn(index)
    }

    // Overriding viewerjs renderImage method to be able to apply over zoom and position when navigated
    Viewer.prototype.original_renderImage_fn = Viewer.prototype.renderImage
    Viewer.prototype.renderImage = (done) => {
      if (this.viewer.imageData && !this.viewer.imageData.overwritten) {
        // Overwrite defaults with our last saved zoom and position values
        if (this.images[this.viewer.index].answered && 'lastZoom' in this.images[this.viewer.index]) {
          this.viewer.imageData.ratio = this.images[this.viewer.index].lastZoom
        } else {
          const defaultStartZoom = this.images[this.viewer.index].startAt || this.config.defaults.zoom.startAt
          if (defaultStartZoom === Constants.HEIGHT_FIT) {
            this.viewer.imageData.ratio = Math.min(this.viewer.viewerData.height / this.viewer.image.naturalHeight, 1.0 / this.dppx)
          } else if (defaultStartZoom === Constants.WIDTH_FIT) {
            this.viewer.imageData.ratio = Math.min(this.viewer.viewerData.width / this.viewer.image.naturalWidth, 1.0 / this.dppx)
          } else {
            this.viewer.imageData.ratio = defaultStartZoom / this.dppx
          }
          this.images[this.viewer.index].initialZoom = this.viewer.imageData.ratio * this.dppx
        }
        this.setZoomLevel(this.viewer.imageData.ratio)

        this.viewer.imageData.width = this.viewer.image.naturalWidth * this.viewer.imageData.ratio
        this.viewer.imageData.height = this.viewer.image.naturalHeight * this.viewer.imageData.ratio
        this.viewer.imageData.left = (this.viewer.viewerData.width - this.viewer.imageData.width) / 2
        this.viewer.imageData.top = (this.viewer.viewerData.height - this.viewer.imageData.height) / 2
        this.viewer.imageData.overwritten = true

        this.viewer.initialImageData = { ...this.viewer.imageData }
      }

      this.viewer.original_renderImage_fn(done)

      if (!this.state.navigated) {
        return
      }

      this.setState({ navigated: false }, () => {
        // Bringing the currently viewed image on top in our cache store
        // This allows for smooth image pinch zoom and eliminates lag
        const imageStore = this._pictures.current.children
        for (let i = 0; i < imageStore.length; i++) {
          if (i === this.viewer.index) {
            imageStore[i].style.display = 'block'
          } else {
            imageStore[i].style.display = 'none'
          }
        }

        if (this.images[this.viewer.index].answered && 'lastPosition' in this.images[this.viewer.index]) {
          this.viewer.moveTo(this.images[this.viewer.index].lastPosition.x, this.images[this.viewer.index].lastPosition.y)
        } else {
          this.viewer.moveTo(this.viewer.canvas.width / 2, this.viewer.canvas.height / 2)
        }

        // update parent component to update resolution display
        this.props.setImage(this.viewer.image)

        // update navigation buttons
        this.updateNavButtons()
        if (this.images[this.viewer.index].task) {
          this.setState({ showTask: true })
        }

        if (!('optimalZoom' in this.images[this.viewer.index]) || this.images[this.viewer.index].type === 'test') {
          if (!this.state.trainingComplete) {
            setTimeout(() => {
              this.setState({ trainingAccuracy: false, trainingComplete: true })
            }, 1000)
          } else {
            this.setState({ trainingAccuracy: false })
          }
        } else {
          this.setState({ trainingAccuracy: true })
          this.updateTrainingComplete()
        }
      })
    }

    Viewer.prototype.original_moveTo_fn = Viewer.prototype.moveTo
    Viewer.prototype.moveTo = (x, y) => {
      if (this.images[this.viewer.index].loaded) {
        this.viewer.original_moveTo_fn(x, y)

        this.activityRecord.viewableArea = this.images[this.viewer.index].viewableArea = this.getImageViewableArea()
      }
    }

    // Save image state before next or previous
    Viewer.prototype.original_next_fn = Viewer.prototype.next
    Viewer.prototype.original_prev_fn = Viewer.prototype.prev

    Viewer.prototype.next = (loop) => {
      if (this.viewer.index >= this.viewer.length - 1) {
        return
      } // disallow past last image

      const navigate = () => {
        this.setState({ showMessage: { show: false, message: this.state.showMessage.message } })
        this.setState({ navigated: true, canContinue: false }, () => {
          this.saveImageState()
          this.viewer.original_next_fn(loop)
          this.saveExperimentData()
        })
      }

      this.updateAccuracy()

      if (!this.isOptimalZoomCorrect()) {
        if (this.images[this.viewer.index].type === 'test') {
          this.images[this.viewer.index].lastZoom = this.images[this.viewer.index].initialZoom
          this.addInteraction()
          this.showOptimalZoomWarning(navigate)
        } else {
          this.showOptimalZoomWarning(null)
        }
        return
      } else {
        if (this.images[this.viewer.index].optimalZoom) {
          this.images[this.viewer.index].lastZoom = this.images[this.viewer.index].initialZoom
          this.addInteraction()
        }
      }

      if (this.config.settings.require_annotation && !this.images[this.viewer.index].answered &&
        !this.images[this.viewer.index].optional) {
        this.showMessage(this.config.settings.require_annotation_prompt)
        return
      }

      navigate()
    }

    Viewer.prototype.prev = (loop) => {
      if (this.viewer.index <= 0) {
        return
      } // disallow past first image

      const navigate = () => {
        this.setState({ showMessage: { show: false, message: this.state.showMessage.message } })
        this.setState({ navigated: true, canContinue: false }, () => {
          this.saveImageState()
          this.viewer.original_prev_fn(loop)
          this.saveExperimentData()
        })
      }

      navigate()
    }

    // we want the viewerjs to also handle our custom keyboard events from config
    Viewer.prototype.original_keydown_fn = Viewer.prototype.keydown
    this.viewer.keydown = (event) => {
      // allowing resetting browser zoom
      if (event.metaKey && event.keyCode === 48) {
        return
      }

      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()

      if (this.state.playAnimation || this.state.preventZoomMessage) {
        return false
      }

      Object.keys(this.config.controls).forEach((control) => {
        const keyMappings = this.config.controls[control].keys.mappings
        this.addKeyAction(event, keyMappings, control)
      })

      this.viewer.original_keydown_fn(event)

      return false
    }

    // disabling default behaviour of viewerjs to not re-center the image on window resize
    Viewer.prototype.original_resize_fn = Viewer.prototype.resize
    Viewer.prototype.resize = () => {
      this.performChecks()
      this.setState({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      })
      Object.keys(this.images).forEach((key) => {
        delete this.images[key].lastPosition
      })
      this.viewer.original_resize_fn()
    }

    // overriding default wheel event of viewerjs to disable mouse scroll inertia
    this.viewer.wheel = (event) => {
      if (!this.viewer.viewed) {
        return
      }

      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()

      // Limit wheel speed to prevent zoom too fast
      if (this.viewer.wheeling) {
        return
      }

      // minimizing the effect of mouse wheel inertia
      if (Math.abs(event.deltaY) >= 2) {
        this.viewer.wheeling = false
      }

      let ratio = Number(this.viewer.options.zoomRatio) || 0.1
      if ('zoom' in this.config.controls) {
        if ('mouse' in this.config.controls.zoom) {
          this.config.controls.zoom.mouse.mappings.forEach((map) => {
            if (map.action === 'wheel') {
              ratio = map.step
            }
          })
        }
      }

      let delta = 1

      let deltaXY = event.deltaY

      let isTrackpad = false

      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        deltaXY = -event.deltaX
      }

      if (event.wheelDelta && event.wheelDelta % 120 !== 0) {
        isTrackpad = true
      }

      if (deltaXY && Math.abs(deltaXY) > 0) {
        delta = deltaXY > 0 ? 1 : -1
        if (Math.abs(deltaXY) >= 50) {
          delta *= 5
        }
      } else if (event.wheelDelta) {
        delta = -event.wheelDelta / 120
      } else if (event.detail) {
        delta = event.detail > 0 ? 1 : -1
      }

      if (!isTrackpad) {
        delta = delta / (4 / (this.config.settings.wheel_sensitivity_factor || 1))
      }

      setTimeout(() => {
        this.saveImageState()
        this.addInteraction()
        this.saveExperimentData()
      }, 100)

      this.activityRecord.mouseDuration += this.getEventDuration('mouse')

      this.addInteraction()
      this.viewer.zoom(-delta * ratio, true, event)
      return false
    }

    // make viewer zoom to center from edges if the pointer is off the image
    Viewer.prototype.original_zoomTo_fn = Viewer.prototype.zoomTo
    Viewer.prototype.zoomTo = (ratio, hasTooltip = false, _originalEvent = null, _zoomable = false) => {
      // prevents from logging interaction if image wasn't ready to zoom
      if (!this.images[this.viewer.index].loaded || !this.viewer.imageData.ratio) {
        return
      }

      const oldRatio = Math.round(this.viewer.imageData.ratio * this.dppx * 100) / 100

      let dummyEvent = null
      if (_originalEvent) {
        dummyEvent = {
          pageX: Math.max(
            this.viewer.imageData.left,
            Math.min(
              this.viewer.imageData.left + this.viewer.imageData.width,
              _originalEvent.pageX
            )
          ),
          pageY: Math.max(
            this.viewer.imageData.top,
            Math.min(
              this.viewer.imageData.top + this.viewer.imageData.height,
              _originalEvent.pageY
            )
          )
        }
      }

      this.viewer.original_zoomTo_fn(ratio, hasTooltip, dummyEvent, _zoomable)

      const newRatio = Math.round(this.viewer.imageData.ratio * this.dppx * 100) / 100
      this.activityRecord.zoomChange += Math.abs(newRatio - oldRatio)
    }

    Viewer.prototype.original_pointerdown_fn = Viewer.prototype.pointerdown
    Viewer.prototype.pointerdown = (event) => {
      this.viewer.original_pointerdown_fn(event)
      this.panStart()
    }

    Viewer.prototype.original_pointerup_fn = Viewer.prototype.pointerup
    Viewer.prototype.pointerup = (event) => {
      this.viewer.original_pointerup_fn(event)
      this.panEnd()
    }

    this.imageLoader(this.addImage)

    if ((Math.abs(window.innerWidth - window.outerWidth) > 20 && window.innerHeight !== window.screen.height) ||
      (window.visualViewport && window.visualViewport.scale > 1)) {
      this.setState({ preventZoomMessage: true })
    } else {
      this.setState({ preventZoomMessage: false })
    }

    window.onresize = () => {
      if (!this.resizing) this.resizing = true
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.resizing = false
        if ((Math.abs(window.innerWidth - window.outerWidth) > 20 && window.innerHeight !== window.screen.height) ||
          (window.visualViewport && window.visualViewport.scale > 1)) {
          this.setState({ preventZoomMessage: true })
        } else {
          this.setState({ preventZoomMessage: false })
        }
        if (this.activityRecord) {
          this.activityRecord.viewablePort = this.getImageViewablePort()
        }
      }, 200)
    }

    window.onblur = () => {
      if (this.activityRecord && !this.resettingToken) {
        this.activityRecord.lastActiveTime = Date.now()
        this.saveExperimentData()
        this.resettingToken = false
      }
    }
  }

  /**
   * React component function
   */
  componentWillUnmount () {
    this.viewer.canvas.removeEventListener('mousedown', this.panStart)
    this.viewer.canvas.removeEventListener('mouseup', this.panEnd)

    if (this.eventMappings) {
      Object.keys(this.eventMappings).forEach(action => {
        this.viewer.canvas.removeEventListener(action, this.eventMappings[action])
      })
    }
    this.viewer.destroy()
    window.onresize = null
    window.onblur = null
    clearTimeout(this.resizeTimeout)
    clearTimeout(this.loadTimer)
  }

  // #region Image Loading logic

  /**
   * Handler for when user clicks continue button.
   * @param {Event} e button event object
   */
  continueLastSession (e) {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    const navigate = () => {
      this.setState({ showMessage: { show: false, message: this.state.showMessage.message } })
      if (!this.state.continueDisabled && this.state.lastSessionIndex !== this.viewer.index) {
        this.setState({ navigated: true, canContinue: false }, () => {
          this.viewer.view(this.state.lastSessionIndex)
        })
      }
    }

    if (!this.isOptimalZoomCorrect()) {
      if (!(this.images[this.viewer.index].type === 'test' && this.images[this.viewer.index].demoPlayed)) {
        this.setState({ navigated: false })
        this.showOptimalZoomWarning(this.images[this.viewer.index].type === 'test' ? navigate : null)
        return
      }
    }

    navigate()
  }

  checkPropertyInObject (params) {
    const obj = params.object
    const keyToSearch = params.keyToSearch
    const valueToMatch = params.valueToMatch
    for (let x = 0; x < obj.length; x++) {
      if (obj[x][keyToSearch] === valueToMatch) {
        return true
      }
    }
    return false
  }

  getObjectById (obj, id) {
    for (let x = 0; x < obj.length; x++) {
      if (obj[x].id === id) {
        return obj[x]
      }
    }
  }

  /**
   * Adds an image to the viewer at the index specified
   * @param {Image} image image to add to the viewer
   */
  addImage (image) {
    if (!this._pictures.current || !image) {
      return
    }
    if (image.loaded) {
      image.image.style.width = image.image.naturalWidth / this.dppx
      image.image.style.height = image.image.naturalHeight / this.dppx
      this._pictures.current.appendChild(image.image)
    } else {
      const dummyImage = document.createElement('img')
      dummyImage.src = ImageNotFound
      this._pictures.current.appendChild(dummyImage)
    }

    this.updateTrainingComplete()

    this.viewer.update()
    this.updateNavButtons()
  }

  /**
   * Handles adding images to the viewer component
   * @param {function} imageSetterCallback takes a function to call with @image and @index as parameters, the function will add the image to DOM
   */
  imageLoader (imageSetterCallback) {
    console.log(this.filenames)
    const loadImageAtIndex = (index, callback) => {
      const path = this.filenames[index].path
      const filename = this.filenames[index] instanceof Object ? path : this.filenames[index]
      const image = new Image()

      if (filename.indexOf('://') > 0 || filename.indexOf('//') === 0) {
        image.src = filename
      } else {
        image.src = Constants.IMAGE_FOLDER_PATH + filename + '?nocache=' + performance.now()
        // image.src = Constants.IMAGE_FOLDER_PATH + filename+'?nocache='+performance.now();
      }

      image.onload = () => {
        console.log('image loaded', this.load_order[0])
        this.attempts = 0
        clearTimeout(this.loadTimer)
        this.setState({ connectionWarning: false })

        image.onload = image.onabort = image.onerror = () => {
        }

        if (this.load_order.length) {
          this.load_order = this.load_order.splice(1, this.load_order.length - 1)
        }

        const alreadyExists = this.checkPropertyInObject({
          object: this.images,
          keyToSearch: 'id',
          valueToMatch: 'id_' + index
        })
        const newIndex = (this.images.length || 0)
        if (!alreadyExists) {
          this.images.push({
            filename,
            image,
            loaded: true,
            answered: false,
            startAt: this.filenames[index].startAt,
            demoPlayed: false,
            accuracyEvaluated: false,
            id: 'id_' + index
          })
        }

        if (this.lastSessionData && this.lastSessionData.experimentData[newIndex]) {
          this.images[newIndex].initialZoom = this.lastSessionData.experimentData[newIndex].initialZoom
          if (this.lastSessionData.experimentData[newIndex].zoom) {
            this.images[newIndex].lastZoom = this.lastSessionData.experimentData[newIndex].zoom / this.dppx
          } else {
            if (this.config.defaults.zoom.startAt === Constants.HEIGHT_FIT) {
              this.images[newIndex].lastZoom = this.images[newIndex].initialZoom = this.viewer.viewerData.height / this.viewer.image.naturalHeight
            } else if (this.config.defaults.zoom.startAt === Constants.WIDTH_FIT) {
              this.images[newIndex].lastZoom = this.images[newIndex].initialZoom = this.viewer.viewerData.width / this.viewer.image.naturalWidth
            } else {
              this.images[newIndex].lastZoom = this.images[newIndex].initialZoom = this.config.defaults.zoom.startAt / this.dppx
            }
          }

          if (this.lastSessionData.experimentData[newIndex].viewableArea) {
            this.images[newIndex].viewableArea = this.lastSessionData.experimentData[newIndex].viewableArea
          }

          if (this.lastSessionData.experimentData[newIndex].viewablePort) {
            this.images[newIndex].viewablePort = this.lastSessionData.experimentData[newIndex].viewablePort
          }

          // If the data is included in the last session we assumed it was answered
          this.images[newIndex].answered = true // this.lastSessionData.experimentData[index].answered;

          if (this.filenames[index] instanceof Object && 'optimalZoom' in this.filenames[index]) {
            this.images[newIndex].accuracyEvaluated = true
            this.images[newIndex].demoPlayed = true
          }
        }
        if (this.filenames[index] instanceof Object) {
          if ('optimalZoom' in this.filenames[index]) {
            this.images[newIndex].optimalZoom = this.filenames[index].optimalZoom
          }
          if ('task' in this.filenames[index]) {
            this.images[newIndex].task = this.filenames[index].task
          }
          if ('type' in this.filenames[index]) {
            this.images[newIndex].type = this.filenames[index].type
          } else if ('optimalZoom' in this.filenames[index]) {
            this.images[newIndex].type = 'training'
          }
        }

        if (callback) {
          callback(this.images[newIndex])
        }

        if (this.load_order.length) {
          loadImageAtIndex(this.load_order[0], (img) => {
            imageSetterCallback(img, this.load_order[0])
          })
        }
      }
      image.onerror = () => {
        const maxAttempts = this.config.settings.max_image_load_attempts >= 0 ? this.config.settings.max_image_load_attempts : 5
        console.log('retrying on error', this.load_order)
        clearTimeout(this.loadTimer)
        if (!window.navigator.onLine) {
          image.onload = image.onabort = image.onerror = () => {
          }
          this.setState({ connectionLostMessage: true })
          return
        }

        if ((++this.attempts) >= maxAttempts) {
          this.attempts = 0
          if (this.load_order.length) {
            this.load_order = this.load_order.splice(1, this.load_order.length - 1)
          }
        }

        const alreadyExists = this.checkPropertyInObject({
          object: this.images,
          keyToSearch: 'id',
          valueToMatch: 'id_' + index
        })

        if (!alreadyExists) {
          this.images.push({
            filename,
            loaded: false,
            startAt: 1.0,
            answered: true,
            initialZoom: 0,
            viewableArea: null,
            viewablePort: null,
            nocache: true,
            id: 'id_' + index
          })
        }
        // const imgObj = this.getObjectById(this.images, 'id_'+index);

        if (callback && (this.attempts === 0)) {
          console.log('FailureSent', this.attempts)
          callback(this.getObjectById(this.images, 'id_' + index))
        }

        if (this.load_order.length) {
          loadImageAtIndex(this.load_order[0], (img) => (imageSetterCallback(img, this.load_order[0])))
        }
      }

      const loadFailHandler = (image) => {
        image.onload = image.onabort = image.onerror = () => {
        }
        clearTimeout(this.loadTimer)

        if (++this.attempts <
          (this.config.settings.max_image_load_attempts >= 0
            ? this.config.settings.max_image_load_attempts
            : 5)) {
          if (this.attempts >
            (this.config.settings.warn_after_image_load_attempts >= 0
              ? this.config.settings.warn_after_image_load_attempts
              : 2)) {
            this.setState({ connectionWarning: true })
          }
          if (this.load_order.length) {
            loadImageAtIndex(this.load_order[0], (img) => (imageSetterCallback(img, this.load_order[0])))
          }
        } else {
          // If download is failed in several retries, then disconnect
          // this.attempts = 0;
          // this.setState({connectionLostMessage: true});

          // Here is the case of failure of several retries of download.
          this.attempts = 0
          if (this.load_order.length) {
            this.load_order = this.load_order.splice(1, this.load_order.length - 1)
          }
          const newIndex = Object.keys(this.images).length || 0
          this.images[newIndex] = {
            filename,
            loaded: false,
            startAt: 1.0,
            answered: true,
            initialZoom: 0,
            viewableArea: null,
            viewablePort: null
          }
          if (callback) {
            callback(this.images[newIndex])
          }

          if (this.load_order.length) {
            loadImageAtIndex(this.load_order[0], (img) => (imageSetterCallback(img, this.load_order[0])))
          }
        }
      }
      // Need to check this carefully.
      // If downloading is failed at first it is better to set time out more than first one.
      const startLoadTimer = (image) => {
        const timeLimit = (this.config.settings.default_image_load_timeout || 5000)
        this.loadTimer = setTimeout(() => {
          loadFailHandler(image)
        }, timeLimit + 2000 * this.attempts)
      }

      startLoadTimer(image)
    }

    if (this.load_order.length) {
      loadImageAtIndex(this.load_order[0], (image) => {
        imageSetterCallback(image, 0)
      })
    }
  }

  /**
   * Continue loading images after loss of connection
   * @param {Event} e receives the event object
   */
  resumeImageLoader (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ connectionLostMessage: false, connectionWarning: false })
    this.attempts = 0
    this.imageLoader(this.addImage)
    return false
  }

  // #endregion Image Loading logic

  // #region Experiment specific functionality

  /**
   * Handler for submit experiment button
   * @param {Event} e receives the event object
   */
  checkExperimentComplete (e) {
    if (!this.isOptimalZoomCorrect()) {
      this.showOptimalZoomWarning(null)
      if (this.images[this.viewer.index].type !== 'test') {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        return
      }
    } else {
      if (this.images[this.viewer.index].optimalZoom) {
        this.images[this.viewer.index].lastZoom = this.images[this.viewer.index].initialZoom
        this.addInteraction()
      }
    }

    const unanswered = Object.keys(this.images).filter((index) => !this.images[index].answered && !this.images[index].optional)
    if (unanswered.length) {
      this.showMessage(this.config.settings.missed_annotation_prompt)
      if (parseInt(unanswered[0]) !== parseInt(this.viewer.index)) {
        this.setState({ navigated: true }, () => this.viewer.view(unanswered[0]))
      }
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    } else {
      this.activityRecord.endTime = Date.now()
      this.saveExperimentData()

      window.performance.clearMarks()
      window.performance.clearMeasures()
      // Fix for Issue #172 - closing fullscreen if experiment has ended
      this.exitFullscreen()
    }
  }

  /**
   * Converts all image data to Object to store in the localstorage and be used by end of experiment UI to generate csv
   * returns Object contains the data for all answered images and the last viewed index
   */
  toObject () {
    this.saveImageState()
    let imageData = {}
    if (this.lastSessionData && this.lastSessionData.experimentData) {
      imageData = this.lastSessionData.experimentData
    }

    Object.keys(this.images).forEach((index) => {
      if (imageData[index] && !('viewablePort' in imageData[index])) {
        imageData[index].viewablePort = this.images[index].loaded ? this.images[index].viewablePort : this.getImageViewablePort()
      }

      if (!this.images[index].answered && this.images[index].loaded) {
        return
      }
      if (!imageData[index]) {
        imageData[index] = {}
      }
      imageData[index].image = this.images[index].filename
      imageData[index].zoom = this.images[index].loaded ? this.images[index].lastZoom * this.dppx : 0
      imageData[index].initialZoom = this.images[index].loaded ? this.images[index].initialZoom : 0

      imageData[index].viewableArea = this.images[index].loaded ? this.images[index].viewableArea : this.getImageViewableArea()
      imageData[index].viewablePort = this.images[index].loaded ? this.images[index].viewablePort : this.getImageViewablePort()

      // This is for testing zoom 0 value
      /* if (imageData[index].zoom === 0) {
        console.warn("toObject() == zoom = " + imageData[index].zoom + " " + imageData[index].image)
        console.warn("this.images[index].loaded " + this.images[index].loaded + " " + this.images[index].lastZoom + " " + this.dppx)
      } else {
        console.log("toObject() == zoom = " + imageData[index].zoom + " " + imageData[index].image)
        console.log("this.images[index].loaded " + this.images[index].loaded + " " + this.images[index].lastZoom + " " + this.dppx)
      } */

      if (this.images[index].optimalZoom) {
        imageData[index].optimalZoom = this.images[index].optimalZoom
      }
    })

    if (this.activityRecord) {
      if (!(this.viewer.index in this.activityLog) || !this.activityLog[this.viewer.index].length) {
        this.activityLog[this.viewer.index] = []
        this.activityLog[this.viewer.index].push({
          startTime: this.openedTime,
          mouseDuration: 0,
          keyboardDuration: 0,
          sliderDuration: 0,
          panDuration: 0,
          zoomChange: 0,
          lastActiveTime: 0
        })
      } else {
        if (this.activityLog[this.viewer.index].length && 'endTime' in this.activityLog[this.viewer.index][this.activityLog[this.viewer.index].length - 1]) {
          this.activityLog[this.viewer.index].push({
            startTime: this.openedTime,
            mouseDuration: 0,
            keyboardDuration: 0,
            sliderDuration: 0,
            panDuration: 0,
            zoomChange: 0,
            lastActiveTime: 0
          })
        }
      }
      const currentIndex = Math.max(this.activityLog[this.viewer.index].length - 1, 0)
      this.activityRecord.lastZoom = this.viewer.imageData.ratio * this.dppx
      this.activityRecord.viewableArea = this.getImageViewableArea()
      this.activityRecord.viewablePort = this.getImageViewablePort()
      this.activityLog[this.viewer.index][currentIndex] = { ...this.activityRecord }
      if (this.activityRecord.endTime) {
        if (this.activityLog[this.viewer.index][currentIndex].mouseDuration +
          this.activityLog[this.viewer.index][currentIndex].keyboardDuration +
          this.activityLog[this.viewer.index][currentIndex].sliderDuration +
          this.activityLog[this.viewer.index][currentIndex].panDuration ===
          0.0) {
          this.activityLog[this.viewer.index].pop()
        } else {
          this.activityLog[this.viewer.index][currentIndex].endTime = this.activityRecord.endTime
        }
      }
    }
    for (const x in imageData) {
      if (!imageData[x].viewablePort) {
        imageData[x].viewablePort = this.getImageViewablePort()
      }
    }
    return {
      experimentData: imageData,
      activityRecord: this.activityLog,
      displayOrder: this.displayOrder,
      accessToken: this.props.urlToken,
      uniqueHash: this.uniqueHash,
      lastIndex: this.viewer.index,
      dppx: this.dppx,
      accuracy: this.accuracy
    }
  }

  /**
   * check if the optimal zoom selected of training image is correct
   */
  isOptimalZoomCorrect () {
    const optimalZoom = this.images[this.viewer.index].optimalZoom
    if (optimalZoom) {
      let min, max
      const currentZoom = Math.round(this.viewer.imageData.ratio * this.dppx * 100)
      if (optimalZoom.length) {
        min = Math.round(Math.min(...optimalZoom) * 100)
        max = Math.round(Math.max(...optimalZoom) * 100)
      } else if (parseFloat(optimalZoom)) {
        min = max = Math.round(optimalZoom * 100)
      } else {
        console.warn('invalid optimalZoom value', optimalZoom)
        return true
      }
      return currentZoom >= min && currentZoom <= max
    }

    return true
  }

  /**
   * show optimal zoom mismatch warning
   */
  showOptimalZoomWarning (callback) {
    if (!this.isOptimalZoomCorrect()) {
      if ((this.images[this.viewer.index].type === 'test' && this.images[this.viewer.index].demoPlayed)) {
        if (callback) {
          callback()
        }
        return
      }

      this.activityRecord.endTime = Date.now()
      this.saveExperimentData()

      window.performance.clearMarks()
      window.performance.clearMeasures()
      this.activityRecord = {
        startTime: Date.now(),
        mouseDuration: 0,
        keyboardDuration: 0,
        sliderDuration: 0,
        panDuration: 0,
        zoomChange: 0
      }

      this.playAnimation(callback)
      this.images[this.viewer.index].demoPlayed = true
    }
  }

  /**
   * Updates the answered flag for the images that has been interacted with by the user
   */
  addInteraction () {
    if (this.state.navigated) return

    if (this.activityRecord.mouseDuration +
      this.activityRecord.sliderDuration +
      this.activityRecord.keyboardDuration >
      0) {
      this.images[this.viewer.index].answered = true
    }

    if (!('lastZoom' in this.images[this.viewer.index])) {
      return
    } // additional safegaurd

    this.images[this.viewer.index].answered = true
  }

  /**
   * Save experiment data in the local storage
   */
  saveExperimentData () {
    localStorage.setItem(Constants.APP_NAME + '_' + this.props.urlToken, JSON.stringify(this.toObject()))
  }

  /**
   * Show warning message
   * @param {String} message message to display
   */
  showMessage (message) {
    this.setState({ showMessage: { show: true, message } }, () => {
      setTimeout(() => {
        this.setState({ showMessage: { show: false, message } })
      }, 7000)
    })
  }

  /**
   * play a little animation to guide user to select correct zoom level
   */
  playAnimation (callback) {
    this.setState({ playAnimation: true }, () => {
      this.viewer.resize()

      const zoomer = document.querySelector('.zoomer')
      zoomer.style.zIndex = 20000

      const interactionBlocker = document.createElement('div')
      interactionBlocker.style.position = 'absolute'
      interactionBlocker.style.top = interactionBlocker.style.left = 0
      interactionBlocker.style.width = '100vw'
      interactionBlocker.style.height = '100vh'
      interactionBlocker.style.zIndex = 20050
      document.body.appendChild(interactionBlocker)

      const slider = zoomer.querySelector('input[type="range"]')
      const currentValue = parseInt(slider.value)
      let targetValue
      if (this.images[this.viewer.index].optimalZoom.length) {
        const min = Math.round(Math.min(...this.images[this.viewer.index].optimalZoom) * 100)
        const max = Math.round(Math.max(...this.images[this.viewer.index].optimalZoom) * 100)
        targetValue = Math.round((min + max) / 2)
      } else {
        targetValue = this.images[this.viewer.index].optimalZoom * 100
      }

      const increment = targetValue - currentValue > 0 ? 1 : -1
      const interval = 20

      let newValue = parseFloat(slider.value)
      setTimeout(() => {
        const endTimer = setInterval(() => {
          if ((increment > 0 && slider.value >= targetValue) || (increment < 0 && slider.value <= targetValue)) {
            const animatiedContainer = document.querySelector('.optimal-zoom-alert-container')
            animatiedContainer.style.animationPlayState = 'paused'
            animatiedContainer.style.top = animatiedContainer.getBoundingClientRect().y + 'px'
            animatiedContainer.classList.remove('blink')
            animatiedContainer.style.animationPlayState = null
            animatiedContainer.classList.add('blinkEnd')

            setTimeout(() => {
              document.querySelector('.optimal-zoom-alert').innerHTML += '<br/><br/>Click anywhere to close.'
              const closeAnimation = () => {
                if (this.state.preventZoomMessage) {
                  return
                }
                slider.value = currentValue
                this.viewer.zoomTo((slider.value / 100) / this.dppx)
                this.props.setZoomLevel(slider.value / 100)
                document.body.removeChild(interactionBlocker)
                zoomer.style.zIndex = null

                this.setState({ playAnimation: false })
                document.removeEventListener('click', closeAnimation)
                document.removeEventListener('keyup', closeAnimation)
                if (callback) callback()
              }
              document.addEventListener('click', closeAnimation)
              document.addEventListener('keyup', closeAnimation)
            }, 1000)
            clearInterval(endTimer)
          } else {
            newValue += increment
            slider.value = newValue
            this.viewer.zoomTo((slider.value / 100) / this.dppx)
            this.props.setZoomLevel(slider.value / 100)
          }
        }, interval)
      }, 1000)
    })
  }

  // #endregion Experiment specific functionality

  // #region Viewer interaction specific functions

  /**
   * Sets the zoom level of the image in view
   * @param {float} level The amount of zoom from 0 to 1
   * @param {string} inputSource (null, slider, viewer)
   *   If the source is slider, this function updates the image
   *   If the source is viewer, this function updates the slider
   *   If the source is null, this function simply updates the zoom display
   */
  setZoomLevel (level, inputSource) {
    if (inputSource === 'slider') {
      this.zoom_type = Constants.SLIDER_ZOOM
    }

    if (this.zoom_type === Constants.SLIDER_ZOOM) {
      this.addInteraction()
      this.viewer.zoomTo(level / this.dppx)
      this.saveExperimentData()
      this.props.setZoomLevel(level)
    }

    if (this.zoom_type === Constants.VIEWER_ZOOM) {
      this.props.setZoomLevel(level * this.dppx)
    }

    this.zoom_type = Constants.VIEWER_ZOOM
  }

  /**
   * Mouse clicked on slider event
   */
  sliderStart () {
    window.performance.mark('sliderStart')
  }

  /**
   * Mouse click ended on slider event
   */
  sliderEnd () {
    const sliderStarted = window.performance.getEntriesByName('sliderStart', 'mark')
    if (!sliderStarted.length) {
      return
    }

    window.performance.mark('sliderEnd')
    window.performance.measure('slider', 'sliderStart', 'sliderEnd')
    const entries = window.performance.getEntriesByName('slider', 'measure')
    if (entries.length > 0) {
      this.activityRecord.sliderDuration += entries[entries.length - 1].duration / 1000
      this.addInteraction()
    }
    window.performance.clearMarks()
    window.performance.clearMeasures()
    this.saveExperimentData()
  }

  /**
   * Mouse clicked on canvas event
   */
  panStart () {
    window.performance.mark('panStart')
  }

  /**
   * Mouse click ended on canvas event
   */
  panEnd () {
    const panStarted = window.performance.getEntriesByName('panStart', 'mark')
    if (!panStarted.length) {
      return
    }

    window.performance.mark('panEnd')
    window.performance.measure('pan', 'panStart', 'panEnd')
    const entries = window.performance.getEntriesByName('pan', 'measure')
    if (entries.length > 0) {
      this.activityRecord.panDuration += entries[entries.length - 1].duration / 1000
      this.addInteraction()
    }
    window.performance.clearMarks()
    window.performance.clearMeasures()
    this.saveExperimentData()
  }

  /**
   * Move to previous image
   */
  nextImage () {
    this.viewer.next()
  }

  /**
   * Move to next available image
   */
  previousImage () {
    this.viewer.prev()
  }

  /**
   * Handles fitting the width of the image in the viewable area
   */
  fitImageWidth () {
    const container = this.viewer.canvas.parentNode
    const containerWidth = container.offsetWidth

    this.viewer.resize()
    this.viewer.zoomTo(Math.min(containerWidth / this.viewer.image.naturalWidth, 1.0 / this.dppx))
    this.saveImageState()
    this.addInteraction()
    this.saveExperimentData()
  }

  /**
   * Handles fitting entire image in the viewable area
   */
  fitImageAll () {
    const container = this.viewer.canvas.parentNode
    const containerHeight = container.offsetHeight

    this.viewer.resize()
    this.viewer.zoomTo(Math.min(containerHeight / this.viewer.image.naturalHeight, 1.0 / this.dppx))
    this.saveImageState()
    this.addInteraction()
    this.saveExperimentData()
  }

  /**
   * Set viewer to fullscreen
   * @param {Boolean} mode enter or exit mode, true = enter, false = exit
   */
  fullscreenMode () {
    if (window.innerHeight === window.screen.height) {
      this.exitFullscreen()
    } else {
      const elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    }
  }

  exitFullscreen () {
    if ((document.fullscreen || document.fullscreenEnabled) && document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {

        })
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }

  /**
   * Updates the state of navigation buttons and submit/continue buttons
   */
  updateNavButtons () {
    if (this.viewer.index === 0) {
      this.setState({ prevVisible: false })
    } else if (!this.state.prevVisible) {
      this.setState({ prevVisible: true })
    }

    if (this.viewer.index >= (this.filenames.length - 1)) {
      this.setState({ nextVisible: false })
      this.setState({ canSubmit: true })
    } else if (!this.state.nextVisible) {
      this.setState({ nextVisible: true })
    }

    if (this.viewer.length > this.viewer.index + 1) {
      this.setState({ nextDisabled: false })
    } else {
      this.setState({ nextDisabled: true })
    }

    if (this.viewer.length > this.state.lastSessionIndex) {
      this.setState({ continueDisabled: false })
    }
  }

  /**
   * Update the accuracy of user's answers
   */
  updateAccuracy () {
    if (!('type' in this.images[this.viewer.index])) {
      return
    }

    if (!this.images[this.viewer.index].accuracyEvaluated) {
      this.images[this.viewer.index].accuracyEvaluated = true

      let evaluatedCount
      if ('type' in this.images[this.viewer.index] &&
        (this.images[this.viewer.index].type === 'training' || this.images[this.viewer.index].type === '')) {
        evaluatedCount = Object.values(this.images).filter(img => img.accuracyEvaluated && img.type === 'training').length
      } else {
        evaluatedCount = Object.values(this.images).filter(img => img.accuracyEvaluated && img.type === 'test').length
      }
      this.accuracy = ((this.accuracy * (evaluatedCount - 1)) + (this.isOptimalZoomCorrect() ? 1 : 0)) / evaluatedCount
    }
  }

  /**
   * Whether accuracy bar should be visible
   */
  accuracyBarVisible () {
    if (this.state.trainingAccuracy) {
      return (Object.values(this.images).filter(img => img.accuracyEvaluated && img.type === 'training').length &&
        !this.state.trainingComplete)
    } else {
      return (Object.values(this.images).filter(img => img.accuracyEvaluated && img.type === 'test').length &&
        this.state.trainingComplete)
    }
  }

  /**
   * Update flag if training is completed
   */
  updateTrainingComplete () {
    if (Object.values(this.images).filter(img => img.type === 'training').length === this.filenames.filter(img => img instanceof Object && (img.type === 'training' || ('optimalZoom' in img && !('type' in img)))).length &&
      Object.values(this.images).filter(img => img.accuracyEvaluated &&
        img.type === 'training').length === Object.values(this.images).filter(img => img.type === 'training').length) {
      this.setState({ trainingAccuracy: true, trainingComplete: true })
    }
  }

  /**
   * Handles showing and hiding task message
   */
  showTaskMessage () {
    this.setState({ showTask: !this.state.showTask })
  }

  /**
   * Update lastZoom and lastPosition to be restored when returning to image previously viewed
   */
  saveImageState () {
    if (this.viewer.imageData.left && this.viewer.imageData.top) {
      this.images[this.viewer.index].lastPosition = { x: this.viewer.imageData.left, y: this.viewer.imageData.top }
    }

    const currentZoom = this.viewer.imageData.ratio
    let startUpZoom
    if (this.config.defaults.zoom.startAt === Constants.HEIGHT_FIT) {
      startUpZoom = Math.min(this.viewer.viewerData.height / this.viewer.image.naturalHeight, 1.0 / this.dppx)
    } else if (this.config.defaults.zoom.startAt === Constants.WIDTH_FIT) {
      startUpZoom = Math.min(this.viewer.viewerData.width / this.viewer.image.naturalWidth, 1.0 / this.dppx)
    } else {
      startUpZoom = this.config.defaults.zoom.startAt / this.dppx
    }

    if ((this.images[this.viewer.index].lastZoom && Math.abs(this.images[this.viewer.index].lastZoom - currentZoom) > 0) ||
       (!('lastZoom' in this.images[this.viewer.index]) && Math.abs(currentZoom - startUpZoom) > 0)) {
      this.images[this.viewer.index].lastZoom = this.viewer.imageData.ratio
      this.images[this.viewer.index].viewableArea = this.getImageViewableArea()
      this.images[this.viewer.index].viewablePort = this.getImageViewablePort()
    }
  }

  // #endregion Viewer interaction specific functions

  // #region Enabling custom controls from config

  /**
   * Executes action associated with custom keys from the config
   * @param {Event} event The actual keyboard event received
   * @param {Object} keyMappings The mappings {keyCode, specialKey, ...options} from config
   * @param {string} op The operation performed by the key combinations ["zoom","next","previous",...,"toggle_size"]
   */
  addKeyAction (event, keyMappings, op) {
    keyMappings.forEach((map) => {
      if (map.specialKey) {
        if ((event.metaKey && map.specialKey === 'meta') || (event.ctrlKey && map.specialKey === 'ctrl') || (event.shiftKey && map.specialKey === 'shift')) {
          if (event.keyCode === map.keyCode) {
            event.preventDefault()
            this.action(op, map)
          }
        }
      } else {
        if (!event.metaKey && !event.ctrlKey && !event.shiftKey) {
          if (event.keyCode === map.keyCode) {
            event.preventDefault()
            this.action(op, map)
          }
        }
      }
    })
  }

  /**
   * Executes action associated with custom mouse action from the config
   * @param {Object} mouseMappings The mappings {action: ["wheel","click","dblclick",etc], ...options} from config
   * @param {string} op The operation performed by the mouse event ["zoom","next","previous",...,"toggle_size"]
   */
  addMouseAction (mouseMappings, op) {
    if (!this.eventMappings) this.eventMappings = {}
    mouseMappings.forEach((map) => {
      if (map.action && map.action !== 'wheel') {
        this.eventMappings[map.action] = (event) => {
          event.preventDefault()
          this.action(op, map, event)
        }
        this.viewer.canvas.addEventListener(map.action, this.eventMappings[map.action])
      }
    })
  }

  /**
   * Executes the action associated with the control type
   * @param {String} op The operation performed by the mouse event ["zoom","next","previous",...,"toggle_size"]
   * @param {Object} config The config values specific to the operation
   * @param {Event} event needed for mouse event to detect the mouse position
   */
  action (op, config, event) {
    switch (op) {
      case 'zoom':
        this.addInteraction()
        this.saveExperimentData()
        this.viewer.zoom(config.direction === 'in' ? config.step : -config.step, true)
        break
      case 'previous':
        this.previousImage()
        break
      case 'next':
        this.nextImage()
        break
      case 'first':
        this.viewer.view(0)
        break
      case 'last':
        this.viewer.view(this.viewer.length - 1)
        break
      case 'toggle_size':
        if (this.viewer.imageData.ratio * this.dppx >= 1.00) {
          this.viewer.resize()
          this.viewer.zoomTo(config.min / this.dppx, false, event)
        } else {
          this.viewer.resize()
          this.viewer.zoomTo(config.max / this.dppx, false, event)
        }
        this.saveImageState()
        this.addInteraction()
        this.saveExperimentData()
        break
      case 'fit_to_width':
        this.fitImageWidth()
        break
      case 'fit_to_height':
        this.fitImageAll()
        break
      case 'fullscreen':
        this.fullscreenMode()
        break
      case 'reset_token':
        localStorage.removeItem(Constants.APP_NAME + '_' + this.props.urlToken)
        this.resettingToken = true
        window.location.reload()
        break
      default:
        break
    }

    if (['zoom', 'toggle_size', 'fit_to_width', 'fit_to_height'].includes(op)) {
      if (event) {
        this.activityRecord.mouseDuration += this.getEventDuration('mouse')
        this.addInteraction()
      } else {
        if (!this.images[this.viewer.index].loaded || !this.viewer.imageData.ratio) {
          return
        } // prevents from logging interaction if image wasn't ready to zoom
        this.activityRecord.keyboardDuration += this.getEventDuration('keyboard')
        this.addInteraction()
      }
    }
  }

  // #endregion Enabling custom controls from config

  /**
   * Calculates the extents of the image viewable in the UI
   */
  getImageViewableArea () {
    const imageX = this.viewer.imageData.left
    const imageY = this.viewer.imageData.top
    const imageWidth = this.viewer.imageData.width
    const imageHeight = this.viewer.imageData.height
    const parentWidth = this.viewer.containerData.width
    const parentHeight = this.viewer.containerData.height

    const imageXExtent = parentWidth - (imageX + imageWidth)
    const imageYExtent = parentHeight - (imageY + imageHeight)

    const round = (number) => Math.round(number * 100) / 100
    const absMin = (number) => Math.abs(Math.min(number, 0))

    return [
      round(absMin(imageX) / imageWidth),
      round(absMin(imageY) / imageHeight),
      round((imageWidth - absMin(imageXExtent)) / imageWidth),
      round((imageHeight - absMin(imageYExtent)) / imageHeight)
    ]
  }

  /**
   *
   */
  getImageViewablePort () {
    return [window.document.body.offsetWidth, window.document.body.offsetHeight]
  }

  /**
   * Shuffles array in place. ES6 version
   * https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
   * @param {Array} a items An array containing the items.
   */
  shuffle (a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  /**
   * Measure and return time duration for named events
   * @param {String} name name of event, 'slider', 'keyboard', 'mouse'
   * @returns {float} duration
   */
  getEventDuration (name) {
    window.performance.mark(name)
    window.performance.measure(name, name)
    const entries = window.performance.getEntriesByName(name, 'measure')
    if (entries.length > 0) {
      return entries[entries.length - 1].duration
    }
    return 0
  }

  /***
   * Reolve a string containing variables and arithmetic
   * @param {String} message string to resolve
   */
  formattedString (message) {
    const varsRegex = /\${(.*?)}/g
    const conditionsRegexGlobal = /\${(.*)\?(.*):(.*)}/g
    const conditionsRegex = /\${(.*)\?(.*):(.*)}/
    const stringRegex = /'(.*)'/
    const varRegexGlobal = /(image|config)(\.([A-Za-z0-9_]*))*/g
    const varRegex = /(image|config)(\.([A-Za-z0-9_]*))*/

    // eslint-disable-next-line
    this.eval = eval;

    const resolveValue = part => {
      if (part.indexOf('null') > -1) {
        return ''
      }

      if (stringRegex.test(part)) {
        return part.slice(1, -1)
      } else if (varRegex.test(part)) {
        if (part === 'image.zoom') {
          return this.images[this.viewer.index].lastZoom
            ? this.images[this.viewer.index].lastZoom * this.dppx
            : this.images[this.viewer.index].initialZoom
        }

        const indexes = part.trim().split('.')

        let object = null
        if (indexes[0] === 'image') {
          object = this.filenames[this.displayOrder[this.viewer.index]]
        } else if (indexes[0] === 'config') {
          object = this.config
        } else {
          console.warn('unknown variable in formatted string')
          return
        }

        indexes.slice(1, -1).forEach(i => {
          object = object[i]
        })
        if (object && parseInt(indexes[indexes.length - 1]) >= 0) {
          if (object.length) {
            object = object[indexes[indexes.length - 1]]
          } else if (parseInt(indexes[indexes.length - 1]) > 0) {
            object = null
          }
        }

        return object
      }

      return this.eval(part)
    }

    let resolvedMessage = message.replace(varsRegex, v => {
      return v.replace(varRegexGlobal, part => resolveValue(part))
    })

    resolvedMessage = resolvedMessage.replace(/round/g, 'Math.round')

    resolvedMessage = resolvedMessage.replace(varsRegex, v => {
      if (conditionsRegex.test(v)) {
        const conditionMatch = [...v.matchAll(conditionsRegexGlobal)]
        if (conditionMatch.length) {
          const parts = conditionMatch[0].slice(1)
          let result

          if (parts[0].indexOf('null') === -1 && resolveValue(parts[0])) {
            result = resolveValue(parts[1].trim())
          } else {
            result = resolveValue(parts[2].trim())
          }

          return result || ''
        }
      } else {
        const matches = [...v.matchAll(varsRegex)]
        const expResult = this.eval(matches[0][1]) ? "'" + this.eval(matches[0][1]).toString() + "'" : matches[0][1]
        return resolveValue(expResult) || ''
      }
    })

    return resolvedMessage
  }
}

ImageViewerComponent.propTypes = {
  config: PropTypes.object.isRequired,
  urlToken: PropTypes.string.isRequired,
  imageList: PropTypes.array.isRequired,
  setImage: PropTypes.func.isRequired,
  setZoomLevel: PropTypes.func.isRequired
}

export default ImageViewerComponent
