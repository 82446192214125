import React from 'react'
import ImageViewerComponent from './ExperimentUI/ImageViewerComponent'
import Toolbar from './ExperimentUI/Toolbar'
import './ExperimentUI.css'

/**
 * Main experiment component serves as container for image viewer, toolbar and footbar
 */
class ExperimentUI extends React.Component {
  /**
   * default react component contructor function implementation
   */
  constructor (props) {
    super(props)
    this.state = {
      currentZoom: 0, // the current zoom level of the image in view
      resolution: { width: 0, height: 0 }, // the resolution of the image in view
      currentImage: null, // the image in view
      canSubmit: false // if the user can submit experiment by reaching the last image
    }

    // getting images list (in json format) filename from url parameters
    this.urlToken = localStorage.getItem('token') // TODO: handle missing token
    this.imageList = JSON.parse(localStorage.getItem('imagelist'))
    this.config = JSON.parse(localStorage.getItem('config'))
    this.randomize = JSON.parse(localStorage.getItem('randomize')) || false
    this.resizing = false
    this.state.totalImages = this.imageList.length
    this.state.currentZoom = this.config.defaults.zoom.startAt

    this._imageViewer = React.createRef() // A pointer to the image viewer DOM element
    this._toolbar = React.createRef() // A pointer to the toolbar DOM element
    this._display = React.createRef() // A pointer to the footbar DOM element

    // exposing function for other components to be able to call
    this.updateViewerZoom = this.updateViewerZoom.bind(this)
    this.updateZoomComponents = this.updateZoomComponents.bind(this)
    this.setCurrentImage = this.setCurrentImage.bind(this)
  }

  /**
   * default react component render function implementation
   */
  render () {
    return (
      <React.Fragment>
        <Toolbar
          ref={this._toolbar}
          zoomValue={this.state.currentZoom}
          fitAll={() => this._imageViewer.current.fitImageAll()}
          fitHeight={() => this._imageViewer.current.fitImageWidth()}
          fullscreen={() => this._imageViewer.current.fullscreenMode()}
          setZoom={this.updateViewerZoom}
          resolution={this.state.resolution}
          sliderStart={() => this._imageViewer.current.sliderStart()}
          sliderEnd={() => this._imageViewer.current.sliderEnd()}
        />

        <ImageViewerComponent
          ref={this._imageViewer}
          urlToken={this.urlToken}
          imageList={this.imageList}
          config={this.config}
          setZoomLevel={this.updateZoomComponents}
          setImage={this.setCurrentImage} />
      </React.Fragment>
    )
  }

  /**
   * default react component componentDidMount function implementation
   */
  componentDidMount () {
    window.onbeforeunload = () => {
      return 'Are you sure you want to leave this page?'
    }

    window.document.body.addEventListener('wheel', (e) => {
      e.preventDefault()
      return false
    }, { passive: false, capture: true })
  }

  /**
   * default react component componentWillUnmount function implementation
   */
  componentWillUnmount () {
    window.onbeforeunload = null
    window.document.body.removeEventListener('wheel', null)
  }

  /**
   * Sets the current image in view, as a result resolution is updated in the footbar
   * @param {Image} image the image to set
   */
  setCurrentImage (image) {
    this.setState({ currentImage: image, resolution: { width: image.naturalWidth, height: image.naturalHeight } })
  }

  /**
   * Informs image viewer component of the zoom value change from other controls
   * @param {float} value value to set
   */
  updateViewerZoom (value) {
    this.setState({ currentZoom: value }, () => {
      this._imageViewer.current.setZoomLevel(this.state.currentZoom, 'slider')
    })
  }

  /**
   * Informs toolbar and footbar of the image viewer component's zoom value so display can be updated
   * @param {float} value value to set
   */
  updateZoomComponents (value) {
    this.setState({ currentZoom: value })
  }
}

export default ExperimentUI
