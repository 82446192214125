export const Constants = {
  SLIDER_ZOOM: 0,
  VIEWER_ZOOM: 1,
  APP_NAME: 'zoomviewer',
  HEIGHT_FIT: 'height-fit',
  WIDTH_FIT: 'width-fit',
  VIEWER_OPTIONS: {
    inline: true,
    backdrop: false,
    button: false,
    fullscreen: false,
    navbar: false,
    rotatable: false,
    scalable: false,
    title: false,
    toolbar: false,
    tooltip: false,
    transition: false,
    loop: false,
    toggleOnDblclick: false
  },
  IMAGE_FOLDER_PATH: 'https://datasets.vqa.mmsp-kn.de/pixabay_images_orig/full/',
  API_URL: (() => {
    if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
      return 'http://127.0.0.1:5000'
    } else if (window.location.hostname === '192.168.1.3') {
      return 'http://192.168.1.3:8000'
    } else {
      return 'https://zovi-flask-api.herokuapp.com'
    }
  })()
}
