import React from 'react'
import '../ExperimentUI.css'
import PropTypes from 'prop-types'
import AdjustSharpIcon from '@material-ui/icons/AdjustSharp'

/**
 * A sub control of image viewer component that displays accuracy during an experiment
 */
class AccuracyDisplay extends React.Component {
  /**
   * default react component render function implementation
   */
  render () {
    const { accuracy, accuracyInfo, showBar, config, showCompleteIcon } = this.props

    return (
      <React.Fragment>
        <div className='accuracy-bar' style={{ opacity: showBar ? 0.9 : 0 }}>
          <div className='accuracy'
               style={{
                 left: `-${(100 - accuracy * 100)}%`,
                 backgroundColor: `rgb(
                    ${(accuracy * 100 <= config.settings.change_accuracy_green_at ? 1 : 0) * 255},
                    ${(accuracy * 100 <= config.settings.minimum_required_accuracy ? 0 : 0.7) * 255},
                    0)`
               }}>
          </div>
          <div className="accuracy-text">{Math.round(accuracy * 100)}%</div>
        </div>
        <div className="accuracy-info" style={{
          opacity: accuracyInfo ? 0.9 : 0,
          top: accuracyInfo && !showBar ? 0 : 30
        }}>
          <CompleteIcon animate={showCompleteIcon && !showBar}/>
          {showBar
            ? <AdjustSharpIcon style={{ fontSize: '12pt', borderRadius: '5px 0 0 5px' }}/>
            : null
          }
          <span style={{ margin: '0 10px 0 10px' }}>
                {accuracyInfo}
            </span>
        </div>
      </React.Fragment>
    )
  }
}

const CompleteIcon = (props) => {
  const [animationPlayed, setAnimationPlayed] = React.useState(false)

  if (props.animate && !animationPlayed) { setAnimationPlayed(true) }

  return (
    <div style={{
      position: props.animate ? 'relative' : 'absolute',
      height: 16,
      display: animationPlayed && !props.animate ? 'none' : null
    }}>
      <div className={props.animate ? 'trigger drawn' : 'trigger'}/>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 37 37"
        version="1.1"
        viewBox="0 0 37 37"
        xmlSpace="preserve"
        className="tick-icon"
      >
        <path
          fill="none"
          stroke="#00b300"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3"
          d="M30.5 6.5h0c6.6 6.6 6.6 17.4 0 24h0c-6.6 6.6-17.4 6.6-24 0h0c-6.6-6.6-6.6-17.4 0-24h0c6.6-6.7 17.4-6.7 24 0z"
          className="circ path"
        />
        <path
          fill="none"
          stroke="#00b300"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3"
          d="M10.6 19L15.9 24.2 26.4 13.8"
          className="tick path"
        />
      </svg>
    </div>
  )
}

AccuracyDisplay.propTypes = {
  accuracy: PropTypes.number.isRequired,
  accuracyInfo: PropTypes.string.isRequired,
  showBar: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  showCompleteIcon: PropTypes.bool.isRequired
}

CompleteIcon.propTypes = {
  animate: PropTypes.bool
}

export default AccuracyDisplay
