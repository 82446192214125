import React from 'react'
import { Helmet } from 'react-helmet'

/**
 * The end of the  experiment page, allows user to download a csv file of all the experiment data
 */
class InvalidPage extends React.Component {
  /**
   * default react component render function implementation
   */
  render () {
    return (
      <React.Fragment>
        <Helmet bodyAttributes={{ style: 'background-color: #474747' }}/>
        <div className="center">
          <h3 style={{ color: 'white' }}>Oops! Something went wrong.</h3>
        </div>
      </React.Fragment>
    )
  }
}

export default InvalidPage
