import React, { createRef } from 'react'
import { Link, Redirect } from 'react-router-dom'
import './StartUI.css'
import { Constants } from './constants'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InputAdornment from '@material-ui/core/InputAdornment'
import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Helmet from 'react-helmet'
import MobileDetect from 'mobile-detect'
import Tooltip from '@material-ui/core/Tooltip'
import Bowser from 'bowser'

/**
 * The start experiment page, the first one that opens the app,
 * unless previous data exists from previous session, then it redirects to experiment page
 */
class StartUI extends React.Component {
  /**
   * default react component constructor function implementation
   */
  constructor (props) {
    super(props)

    this.mobileInfo = new MobileDetect(navigator.userAgent)
    // moved these checks inside function performChecks();

    this.state = {
      screenListLoaded: false,
      customField: '',
      customFieldTitle: '',
      virtualWidth: '',
      virtualHeight: '',
      nativeWidth: '',
      nativeHeight: '',
      userVirtualWidth: '',
      userVirtualHeight: '',
      userNativeWidth: '',
      userNativeHeight: '',
      screenSize: '',
      screenSizeUnit: 'inches',
      system: {},
      errorOccured: false,
      imageList: [],
      config: null,
      showMessage: { show: false, message: '' },
      resolutionDialog: { show: false, messageType: '' },
      fixZoomDialog: false,
      allowedScreenSize: true,
      isMac: false,
      configLoaded: false,
      imageListLoaded: false
    }

    this.performChecks()

    this.enteredScreenSizes = []
    this.urlToken = new URLSearchParams(window.location.search).get('token') || 'debug'
    window.addEventListener('resize', () => {
      this.initResolution()
    })

    for (const item of ['token', 'config', 'randomize', 'dppx']) {
      localStorage.removeItem(item)
    }
    localStorage.setItem('token', this.urlToken)

    this._verticalHeadingContainer = createRef()
  }

  restoreUserInfo() {
    let userInfo = localStorage.getItem(Constants.APP_NAME + '_userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.setState({
        virtualWidth: userInfo.virtualWidth || '',
        virtualHeight: userInfo.virtualHeight || '',
        userVirtualWidth: userInfo.userVirtualWidth || '',
        userVirtualHeight: userInfo.userVirtualHeight || '',
        nativeWidth: userInfo.nativeWidth || '',
        nativeHeight: userInfo.nativeHeight || '',
        userNativeWidth: userInfo.userNativeWidth || '',
        userNativeHeight: userInfo.userNativeHeight || '',
        screenSize: userInfo.screenSize || '',
        screenSizeUnit: userInfo.screenSizeUnit || 'inches',
        customField: userInfo.customField || userInfo.nickname || '',
        customFieldTitle: userInfo.customFieldTitle || '',
        enteredScreenSizes: userInfo.enteredScreenSizes || []
      })
    }
  }

  initResolution() {
    let userInfo = localStorage.getItem(Constants.APP_NAME + '_userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
    }

    if (this.state.isMac) {
      this.setState({
        virtualWidth: '',
        virtualHeight: ''
      })
      if (!userInfo || !userInfo.userVirtualWidth || !userInfo.userVirtualHeight) {
        this.setState({
            userVirtualWidth: '',
            userVirtualHeight: '',
        })
      }
      if (userInfo && userInfo.deviceName && this.screenData) {
        const _system = this.screenData.filter(sys => sys.name === userInfo.deviceName)[0] || {}
        this.setState({ system: _system })
        setTimeout(() => { this.selectResolution(_system)}, 200)
      }
    } else {
      this.setState({
        virtualWidth: Math.trunc(window.screen.width * window.devicePixelRatio),
        virtualHeight: Math.trunc(window.screen.height * window.devicePixelRatio)
      })
      if (!userInfo || !userInfo.userVirtualWidth || !userInfo.userVirtualHeight) {
        this.setState({
            userVirtualWidth: Math.trunc(window.screen.width * window.devicePixelRatio),
            userVirtualHeight: Math.trunc(window.screen.height * window.devicePixelRatio),
        })
      }
    }

    console.log("vW:", this.state.virtualWidth,
    "vH:", this.state.virtualHeight,
    "uVW:", this.state.userVirtualWidth,
    "uVH:", this.state.userVirtualHeight,
    "nW:", this.state.nativeWidth,
    "nH:", this.state.nativeHeight)
  }


  // versionChecks
  versionChecks (data) {
    /* Clear Local Storage; Check if the incoming app version is
        different form the existing one. If so, clear the localstorage object
        to prevent clashes with stale objects.
      */
    const appVersion = localStorage.getItem('appVersion')
    if (!appVersion || (appVersion !== data.app_version)) {
      // Clear localStorage Object for images.
      localStorage.removeItem('zoomviewer_debug')
      localStorage.removeItem('zoomviewer_userInfo')
      console.log('Ls cleared', appVersion, data.app_version)
      // Updating the app version.
      localStorage.setItem('appVersion', data.app_version)
      window.location.reload()
    } else {
      console.log('Version numbers are same - no change to LS object', appVersion, data.app_version)
    }
  }

  // Perform checks
  performChecks () {
    this.mobileInfo = new MobileDetect(navigator.userAgent)
    this.isTablet = this.mobileInfo.tablet()

    // Using virtual pixels incase devicePixelRatio isn't supported;
    const _devicePixelRatio = window.devicePixelRatio || 1
    if (!window.chrome) { return }

    this.isMac = navigator.userAgent.indexOf('Macintosh') > -1

    // this.setState({isMac:navigator.userAgent.indexOf("Macintosh") > -1});
    this.isWindows = navigator.userAgent.indexOf('Windows') > -1

    const browser = Bowser.getParser(window.navigator.userAgent)
    this.isChrome = browser.satisfies({ chrome: '>50' })

    this.isMobile = this.mobileInfo.mobile() || navigator.userAgent.indexOf('CriOS') > -1

    if (this.state.config && this.state.config.settings) {
      this.setState({ isMac: navigator.userAgent.indexOf('Macintosh') > -1 })
      const nativeScreenWidth = (window.screen.width * _devicePixelRatio)
      const nativeScreenHeight = (window.screen.height * _devicePixelRatio)
      const settings = this.state.config.settings

      /* Check if the user's screen is within permissible limits as per the window.screen.width property.
         If not show the /device page. This condition will be rechecked when the screen changes. */
      if (nativeScreenWidth < settings.minAllowedNativeScreenSize.width || nativeScreenHeight < settings.minAllowedNativeScreenSize.height) {
        this.setState({ allowedScreenSize: false })
      } else {
        this.setState({ allowedScreenSize: true })
      }
      /* A second test regarding wrt window.innerWidth is performed in imageViewerComponent.js, which triggers a
         resize warning overlay message instead of redirecting to the error page */
    }

    if (this.isMobile || this.allowedScreenSize) {
      return
    }

    //Check if zoom level is 0 before proceeding - applicable only for windows.
    if (!this.isMac) {
      if (Math.abs(window.innerWidth - window.outerWidth) > 20) {
        this.setState({ fixZoomDialog: true })
      }
    }
  }

  /**
   * default react component render function implementation
   */
  render () {
    if ((this.isMobile || !this.state.allowedScreenSize) && this.state.config) {
      return <Redirect to={{
        pathname: "/device",
        state: { data: this.state.config }
      }}
      />
    }

    if (!this.isChrome) {
      return <Redirect to="/chrome"/>
    }

    if (!this.urlToken) {
      return <Redirect to="/invalid"/>
    }

    const lastSessionExists = localStorage.getItem(Constants.APP_NAME + "_" + this.urlToken)

    if (this.state.errorOccured) {
      return <Redirect to="/invalid"/>
    } else if (lastSessionExists && this.state.imageListLoaded && this.state.configLoaded) {
      return <Redirect to="/experiment"/>
    } else if (!lastSessionExists) {
      return (
        <React.Fragment>
          <Helmet bodyAttributes={{ class: "start-ui-bg" }}/>
          <h1 style={{ position: "absolute", margin: 20, color: "white" }}>ZOVI</h1>
          <div className="start-ui-center">
            <div className="alert-container">
              <div className="alert" style={this.state.showMessage.show ? { opacity: "0.9" } : { opacity: "0" }}>
                <span className="closebtn" onClick={() => {
                  this.setState({ showMessage: { show: false, message: this.state.showMessage.message } })
                }}>&times;</span>
                {
                  this.state.showMessage.message.split("\n").map((m, i) => (
                    <React.Fragment key={i}>
                      {m} <br/>
                    </React.Fragment>
                  ))
                }
              </div>
            </div>
            {this.state.config
              ? <div style={{ color: "white" }}
  dangerouslySetInnerHTML={{ __html: this.state.config.settings.start_message }}/>
              : null
            }
            {this.state.config && this.state.config.settings.custom_field_title
              ? <div className="content single">
                <TextField
                  label={this.state.config.settings.custom_field_title}
                  fullWidth
                  helperText={this.state.config.settings.custom_field_subscript}
                  color="secondary"
                  size="small"
                  value={this.state.customField}
                  onChange={(e) => {
                    this.setState({ customField: e.target.value })
                  }}
                />
              </div>
              : null
            }
            <div className="content side-space" ref={this._verticalHeadingContainer}>
              <h3 className="vertical" style={{
                height: this._verticalHeadingContainer.current
                  ? this._verticalHeadingContainer.current.offsetHeight
                  : null
              }}>System Information</h3>

              <div className="resolution-input">
                {this.isMac && this.state.screenListLoaded
                  ? <Autocomplete
                    freeSolo
                    fullWidth
                    defaultValue={this.state.system}
                    id="system-select"
                    size="small"
                    {...this.defaultProps}
                    onChange={(e, system) => this.selectResolution(system)}
                    renderInput={(params) => <TextField {...params} label="Select system"/>}
                  />
                  : null}
              </div>
              <div className="resolution-input">
                <div style={{ width: "100%" }}>
                  <label>{this.isMac ? "Screen" : "Active"} Resolution</label>
                </div>
                <TextField
                  helperText="width"
                  value={this.state.userVirtualWidth}
                  onChange={(e) => {
                    this.setState({ userVirtualWidth: e.target.value })
                  }}
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">px</InputAdornment>
                  }}
                />
                <TextField
                  helperText="height"
                  value={this.state.userVirtualHeight}
                  onChange={(e) => {
                    this.setState({ userVirtualHeight: e.target.value })
                  }}
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">px</InputAdornment>
                  }}
                />
                <Tooltip title={
                  this.isMac
                    ? "Choose Apple menu > System Preferences, click Displays, then click Display to find your virtual resolution."
                    : (this.isWindows
                        ? "Select Start menu > Settings > System > Display, scroll down to Resolution if needed."
                        : "The selected display resolution on your system")
                }>
                  <button className="info-button"><strong>?</strong></button>
                </Tooltip>
                {
                this.state.system && this.state.system.custom
                  ? <Tooltip title="Auto fill virtual resolution">
                    <button className="info-button" style={{ marginRight: 5 }}
                            onClick={() => this.confirmResolution("virtual")}>
                      <EditIcon style={{ marginLeft: -4, fontSize: 16 }}/>
                    </button>
                  </Tooltip>
                  : null
              }
              </div>
              {this.isMac
                ? <div className="resolution-input">
                  <div style={{ width: "100%" }}>
                    <label>Native Resolution</label>
                  </div>
                  <TextField
                    helperText="width"
                    value={this.state.userNativeWidth}
                    onChange={(e) => {
                      this.setState({ userNativeWidth: e.target.value })
                    }}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">px</InputAdornment>
                    }}
                  />
                  <TextField
                    helperText="height"
                    value={this.state.userNativeHeight}
                    onChange={(e) => {
                      this.setState({ userNativeHeight: e.target.value })
                    }}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">px</InputAdornment>
                    }}
                  />
                  <Tooltip
                    title="Choose Apple menu > About This Mac, click Displays. You should see the built-in display resolution.">
                    <button className="info-button"><strong>?</strong></button>
                  </Tooltip>
                  {this.state.system && this.state.system.custom
                    ? <Tooltip title="auto fill native resolution">
                      <button className="info-button" style={{ marginRight: 5 }}
                              onClick={() => this.confirmResolution("native")}>
                        <EditIcon style={{ marginLeft: -4, fontSize: 16 }}/>
                      </button>
                    </Tooltip>
                    : null
                  }
                </div>
                : null
              }
              <div className="resolution-input">
                <div style={{ width: "100%" }}>
                  <label>Screen diagonal length</label>
                </div>
                <div className="join">
                  <TextField
                    helperText="size"
                    value={this.state.screenSize}
                    onChange={(e) => {
                      this.setState({ screenSize: e.target.value })
                    }}
                    size="small"
                    type="number"
                  />
                  <TextField
                    select
                    value={this.state.screenSizeUnit}
                    onChange={(e) => {
                      this.setState({ screenSizeUnit: e.target.value })
                    }}
                    size="small"
                  >
                    <MenuItem key="inches" value="inches">inches</MenuItem>
                    <MenuItem key="cm" value="cm">cm</MenuItem>
                  </TextField>
                </div>
              </div>
            </div>
            <Link className="green-button" to="/experiment" onClick={(e) => this.startExperiment(e)}>Start
              Experiment</Link>
          </div>
          <div id="dpi" style={{ height: "1in", width: "1in", left: "100%", position: "fixed", top: "100%" }}/>
          {this.state.resolutionDialog.show ? this.fillResolutionDialog(this.state.resolutionDialog.messageType) : null}
          {this.state.fixZoomDialog ? this.fixZoomDialog() : null}
        </React.Fragment>
      )
    } else {
      return <h3>Loading...</h3>
    }
  }

  /**
   * default react component componentDidMount function implementation
   */
  componentDidMount () {
    // load config and image file from meta config based for the given URL token
    fetch('config/meta.config.json', { cache: 'no-store' })
      .then((r) => r.json())
      .then((data) => {
        const configfile = data[this.urlToken].config || 'config.json'
        // const imagelistfile = data[this.urlToken].imagelist || "imagelist.json";
        const imagelistfile = data[this.urlToken].imagelist || 'imagelist.json'
        delete data[this.urlToken].config
        delete data[this.urlToken].imagelist
        const metaconfig = data[this.urlToken]

        // TODO: handle file not found
        fetch('config/' + configfile, { cache: 'no-store' })
          .then((r) => r.json())
          .then((data) => {
            // Perform Version checks, and clear LS cache if necessary.
            this.versionChecks(data)
            const config = data
            config.settings = Object.assign(config.settings, metaconfig)
            this.setState({ config: config }, () => {
              if (this.state.config.settings.custom_field_title !== this.state.customFieldTitle) {
                this.setState({
                  customField: '',
                  customFieldTitle: this.state.config.settings.custom_field_title || ''
                })
              }
              localStorage.setItem('config', JSON.stringify(this.state.config))
            })
            this.performChecks()
            this.restoreUserInfo();
            this.setState({ configLoaded: true })
          })
          .catch((error) => {
            this.setState({ errorOccured: true })
            console.log(error)
          })

        // TODO: handle file not found
        fetch('config/' + imagelistfile, { cache: 'no-store' })
          .then((r) => r.json())
          .then((data) => {
            this.setState({ imageList: data }, () => {
              console.log('LocalStorage set for imagelist', this.state.imageList)
              localStorage.setItem('imagelist', JSON.stringify(this.state.imageList))
            })
            this.initResolution()
            this.restoreUserInfo()
            this.setState( {imageListLoaded: true})
          })
          .catch((error) => {
            this.setState({ errorOccured: true })
            console.log(error)
          })
      })
      .catch((error) => {
        this.setState({ errorOccured: true })
        console.log(error)
      })

    if (this.isMac) {
      fetch('screens.json', { cache: 'no-store' })
        .then((r) => r.json())
        .then((data) => {
          this.screenData = data.filter((field) => field.dppx && parseFloat(field.d) >= 11.0)
          this.screenData.push({ name: 'Other device', custom: true })

          this.defaultProps = {
            options: this.screenData,
            getOptionLabel: (option) => option.name
          }
          this.initResolution()
          this.restoreUserInfo()
          this.setState({ screenListLoaded: true })
        })
    }
  }

  /**
   * default react component componentWillUnmount function implementation
   */
  componentWillUnmount () {
    // window.document.body.removeEventListener('wheel', null);
  }

  /**
   * Show dialog to confirm autofill resolution
   * @param {String} type type of resolution to confirm
   */
  fillResolutionDialog (type) {
    return (
      <Dialog
        open={this.isMac && this.state.resolutionDialog.show}
        onClose={() => this.setState({ resolutionDialog: { show: false } })}
      >
        <DialogTitle>Confirm action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want the {type} resolution calculated?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            type === 'virtual' ? this.estimateVirtualRes() : this.estimateNativeRes()
          }} color='primary'>
            Yes
          </Button>
          <Button onClick={() => this.setState({ resolutionDialog: { show: false } })} color='primary' autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  /**
   * Warn to fix zoom level before continuing to experiment
   */
  fixZoomDialog () {
    return (
      <Dialog
        open={this.state.fixZoomDialog}
        onClose={() => {this.setState({ fixZoomDialog: false })}}
      >
        <DialogTitle>Check your browser settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please reset browser zoom and check your display resolution to continue to experiment. You can do this using the following keyboard
            shortcut:<br/>
            <kbd>Command</kbd>+<kbd>0</kbd> on Mac<br/>
            <kbd>Control</kbd>+<kbd>0</kbd> on Windows/Linux
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ fixZoomDialog: false })} color='primary' autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getPhysicalMonitorSize () {
    if (document.getElementById('dpi') == null) { return 0 }
    const dpiX = document.getElementById('dpi').offsetWidth
    const dpiY = document.getElementById('dpi').offsetHeight
    const width = window.screen.width / dpiX
    const height = window.screen.height / dpiY
    return Math.sqrt(width * width + height * height).toFixed(2)
  }

  /**
   * Handler for start experiment button
   * @param {Event} e receives the event object
   */
  startExperiment (e) {
    if ((this.isMac ? this.state.system : true) &&
      parseFloat(this.state.virtualWidth) && parseFloat(this.state.virtualHeight) &&
      (this.isMac ? parseFloat(this.state.nativeWidth) && parseFloat(this.state.nativeHeight) : true) &&
      parseFloat(this.state.screenSize) &&
      ((this.state.screenSizeUnit === 'inches' && parseFloat(this.state.screenSize) >= 11.0 &&
        parseFloat(this.state.screenSize) <= 500.0) ||
        (this.state.screenSizeUnit === 'cm' && parseFloat(this.state.screenSize) >= 27.94 &&
          parseFloat(this.state.screenSize) <= 1270.0)) &&
      (this.isMac
        ? (parseFloat(this.state.nativeWidth) / parseFloat(this.state.nativeHeight) ===
        parseFloat(this.state.virtualWidth) / parseFloat(this.state.virtualHeight))
        : true) &&
      this.state.imageList && this.state.config) {
      // detect browser zoom https://stackoverflow.com/questions/4130649/detect-browser-zoom-level-using-javascript
      if ((Math.abs(window.innerWidth - window.outerWidth) > 20 && window.innerHeight !== window.screen.height) ||
        (window.visualViewport && window.visualViewport.scale > 1)) {
        this.setState({ fixZoomDialog: true })
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        return
      }
      localStorage.setItem('dppx', this.isMac ? (this.state.nativeWidth / this.state.virtualWidth) : window.devicePixelRatio)
      localStorage.setItem(Constants.APP_NAME + '_userInfo', JSON.stringify({
        deviceName: this.state.system.name,
        nativeWidth: this.state.nativeWidth,
        nativeHeight: this.state.nativeHeight,
        userNativeWidth: this.state.userNativeWidth,
        userNativeHeight: this.state.userNativeHeight,
        virtualWidth: this.state.virtualWidth,
        virtualHeight: this.state.virtualHeight,
        userVirtualWidth: this.state.userVirtualWidth,
        userVirtualHeight: this.state.userVirtualHeight,
        screenSize: this.state.screenSize,
        screenSizeUnit: this.state.screenSizeUnit,
        customField: this.state.customField,
        customFieldTitle: this.state.config.settings.custom_field_title,
        enteredScreenSizes: this.enteredScreenSizes
      }))
      // navigate
    } else {
      const messages = []
      if (this.isMac && !('name' in this.state.system)) {
        messages.push('Please select a system.')
      } else if (!(parseFloat(this.state.virtualWidth) && parseFloat(this.state.virtualHeight))) {
        messages.push('Please specify a valid ' + (this.isMac ? 'screen' : 'active') + ' resolution: width and height.')
      } else if (this.isMac && !(parseFloat(this.state.nativeWidth) && parseFloat(this.state.nativeHeight))) {
        messages.push('Please specify a valid native resolution: width and height.')
      } else if (!this.state.screenSize) {
        messages.push('Please specify a valid screen size in inches or cm.')
      } else if (!((this.state.screenSizeUnit === 'inches' && parseFloat(this.state.screenSize) <= 500.0) ||
        (this.state.screenSizeUnit === 'cm' && parseFloat(this.state.screenSize) <= 1270.0))) {
        this.enteredScreenSizes.push(this.state.screenSizeUnit === 'inches' ? this.state.screenSize : this.state.screenSize / 2.54)
        messages.push('Please specify a valid screen size. The value entered is too large.')
      } else if (!((this.state.screenSizeUnit === 'inches' && parseFloat(this.state.screenSize) >= 11.0) ||
        (this.state.screenSizeUnit === 'cm' && parseFloat(this.state.screenSize) >= 27.94))) {
        this.enteredScreenSizes.push(this.state.screenSizeUnit === 'inches' ? this.state.screenSize : this.state.screenSize / 2.54)
        messages.push('To be able to take this experiment you must use a device at least 11 inches (or 28 cm) in size.')
      } else if (this.isMac && parseFloat(this.state.nativeWidth) / parseFloat(this.state.nativeHeight) !== (parseFloat(this.state.virtualWidth) / parseFloat(this.state.virtualHeight))) {
        messages.push('Aspect ratio does not match. Please select the correct system or select \'Other device\', if your device is not in the list.')
      }
      this.setState({ showMessage: { show: true, message: messages.join('\n') } }, () => {
        setTimeout(() => {
          this.setState({ showMessage: { show: false, message: this.state.showMessage.message } })
        }, 7000)
      })
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }
  }

  /**
   * Handler for system selection button
   * @param {Object} system receives the system information for user selection
   */
  selectResolution (system) {
    if (this.isMac) {
      if (system) {
        if (!system.custom && window.screen.width / window.screen.height !== system.w / system.h) {
          this.setState({
            showMessage: {
              show: true,
              message: 'Aspect ratio does not match. Please select correct system or select \'Other device\', if your device is not in the list.'
            }
          }, () => {
            setTimeout(() => {
              this.setState({ showMessage: { show: false, message: this.state.showMessage.message } })
            }, 7000)
          })
          return
        }

        this.setState({
          system: system,
          virtualWidth: window.screen.width,
          virtualHeight: window.screen.height,
          userVirtualWidth: window.screen.width,
          userVirtualHeight: window.screen.height,
          screenSizeUnit: 'inches'
        })

        // Updating State only if values are present to prevent overriding existing values with empty ones.
        if (system.w) {
          this.setState({
            nativeWidth: system.w || '',
            nativeHeight: system.h || '',
            userNativeWidth: system.w || '',
            userNativeHeight: system.h || ''
          })
        }
        if (system.d) {
          this.setState({
            screenSize: system.d || ''
          })
        }

      } else {

        this.setState({
          system: {},
          virtualWidth: '',
          virtualHeight: '',
          userVirtualWidth: '',
          userVirtualHeight: '',
          nativeWidth: '',
          nativeHeight: '',
          userNativeWidth: '',
          userNativeHeight: '',
          screenSize: '',
          screenSizeUnit: 'inches'
        })
      }
    } else {
      this.setState({
        virtualWidth: Math.trunc(window.screen.width * window.devicePixelRatio),
        virtualHeight: Math.trunc(window.screen.height * window.devicePixelRatio)
      })
    }
  }

  /**
   * Caculate and fill virtual resolution
   */
  estimateVirtualRes () {
    this.setState({
      userVirtualWidth: window.screen.width,
      userVirtualHeight: window.screen.height,
      virtualWidth: window.screen.width,
      virtualHeight: window.screen.height,
      resolutionDialog: { show: false }
    })
  }

  /**
   * Caculate and fill native resolution
   */
  estimateNativeRes () {
    this.setState({
      userNativeWidth: Math.trunc(window.screen.width * window.devicePixelRatio),
      userNativeHeight: Math.trunc(window.screen.height * window.devicePixelRatio),
      nativeWidth: Math.trunc(window.screen.width * window.devicePixelRatio),
      nativeHeight: Math.trunc(window.screen.height * window.devicePixelRatio),
      resolutionDialog: { show: false }
    })
  }

  /**
   * Confirm with user if resolution should be estimated
   * @param {String} type type of resolution to confirm
   */
  confirmResolution (type) {
    if (window.innerWidth !== window.outerWidth ||
      (window.visualViewport && window.visualViewport.scale > 1)) {
      this.setState({ fixZoomDialog: true })
      return
    }
    this.setState({ resolutionDialog: { show: true, messageType: type } })
  }
}

export default StartUI
