import React from 'react'
import './SubmitUI.css'
import { Constants } from './constants'
import { Helmet } from 'react-helmet'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'

/**
 * The end of the  experiment page, allows user to download a csv file of all the experiment data
 */
class SubmitUI extends React.Component {
  /**
   * default react component constructor function implementation
   */
  constructor (props) {
    super(props)

    this.state = {
      uniqueHash: '',
      csv: '',
      csvConstructed: false,
      submittedToAPI: false,
      submissionFailed: false,
      connectionFailed: false
    }

    this.config = JSON.parse(localStorage.getItem('config'))
    this.urlToken = localStorage.getItem('token')
    this.storedData = JSON.parse(localStorage.getItem(Constants.APP_NAME + '_' + this.urlToken))
    this.accuracy = this.storedData.accuracy
    this.passed = (this.accuracy * 100) >= (this.config.settings.minimum_required_accuracy || 0)
    this.userInfo = localStorage.getItem(Constants.APP_NAME + '_userInfo')

    this._hashContainer = React.createRef()

    this.copyHash = this.copyHash.bind(this)
  }

  /**
   * default react component render function implementation
   */
  render () {
    return (
      <React.Fragment>
        <Helmet bodyAttributes={{ class: 'start-ui-bg' }}/>
        <h1 style={{ position: 'absolute', margin: 20, color: 'white' }}>ZOVI</h1>

        {!this.state.submittedToAPI
          ? <div className="submit-ui-center"
               style={{ backgroundColor: 'rgb(0, 0, 0, 0.3)', backdropFilter: 'blur(2px)', color: 'white' }}>
            {!this.state.submissionFailed && !this.state.connectionFailed
              ? <React.Fragment>
                <h3 style={{ margin: 20, color: 'white' }}>Submitting Results</h3>
                <div className="submit-ui-loader"/>
              </React.Fragment>
              : <React.Fragment>
                {
                  this.state.submissionFailed
                    ? <p>
                      <ErrorIcon fontSize="small" style={{ color: '#ff0000', verticalAlign: 'middle', marginRight: 10 }}/>
                      {this.config.settings.submission_error_occured_message ||
                      'Error occured submitting results! Please try again.'}
                    </p>
                    // this.state.connectionFailed
                    : <p>
                      <WarningIcon fontSize="small"
                                   style={{ color: '#ffcc00', verticalAlign: 'middle', marginRight: 10 }}/>
                      {this.config.settings.submission_unstable_connection_message ||
                      'Unstable connection! Please check your connection and try again.'}
                    </p>
                }
                <a className="green-button" href="/submit" onClick={(e) => this.retrySubmission(e)}>Retry</a>
              </React.Fragment>
            }

          </div>
          : <div className="submit-ui-center">
            <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: this.config.settings.submit_heading }}/>
            <div style={{ color: 'white' }}
  dangerouslySetInnerHTML={{ __html: this.passed ? this.config.settings.submit_message : this.config.settings.accuracy_not_met_message }}/>
            {
              this.passed
                ? <div className="submit-content">
                  <button className="copy-btn" onClick={this.copyHash}>copy</button>
                  <input ref={this._hashContainer} className="hash-code" readOnly defaultValue={this.state.uniqueHash}/>
                </div>
                : null
            }
            <React.Fragment>
              {this.state.csvConstructed
                ? <a className="green-button" href={
                  'data:text/plain;charset=utf-8,' + encodeURIComponent(this.state.csv)
                } download="output.csv">Download CSV</a>
                : null
              }
            </React.Fragment>
          </div>
        }
      </React.Fragment>
    )
  }

  /**
   * default react component componentDidMount function implementation
   */
  componentDidMount () {
    this.processUserInfo()
    this.sendRequest()

    window.onkeydown = (event) => {
      // allowing resetting browser zoom
      if (event.metaKey && event.keyCode === 48) {
        return
      }

      Object.keys(this.config.controls).forEach((control) => {
        const keyMappings = this.config.controls[control].keys.mappings
        this.addKeyAction(event, keyMappings, control)
      })
    }
  }

  processUserInfo () {
    // format user info
    if (this.userInfo) {
      if (!(this.userInfo instanceof Object)) {
        this.userInfo = JSON.parse(this.userInfo)
      }
      this.backup_userInfo = JSON.parse(JSON.stringify(this.userInfo))

      this.userInfo.reportedDppx = window.devicePixelRatio
      this.userInfo.userAgent = JSON.stringify(navigator.userAgent)
      this.userInfo.appVersion = this.config.app_version

      if ('enteredScreenSizes' in this.userInfo) {
        this.userInfo.enteredScreenSizes.push(this.userInfo.screenSizeUnit === 'inches' ? this.userInfo.screenSize : this.userInfo.screenSize / 2.54)
        this.userInfo.screenSizeUnit = 'inches'
        this.userInfo.enteredScreenSizes = this.userInfo.enteredScreenSizes.slice(Math.max(this.userInfo.enteredScreenSizes.length - 2, 0))
        this.userInfo.screenSize = this.userInfo.enteredScreenSizes.join(';')
      }
    }

    this.experimentData = this.storedData.experimentData
    this.activityRecord = this.storedData.activityRecord
    this.uniqueHash = this.storedData.uniqueHash

    this.request = {
      userInfo: this.userInfo,
      experimentData: this.experimentData,
      activityLog: this.activityRecord,
      token: this.urlToken,
      uniqueHash: this.uniqueHash
    }

    this.setState({ uniqueHash: this.uniqueHash })
  }

  retrySubmission (e) {
    e.preventDefault()
    this.setState({
      connectionFailed: false,
      submissionFailed: false
    }, () => {
      this.sendRequest()
    })

    return false
  }

  async sendRequest () {
    const handleError = () => {
      if (!window.navigator.onLine) {
        this.setState({ connectionFailed: true })
      } else {
        if (!this.config.settings.require_api_submission) {
          this.setState({ submittedToAPI: true })
          return
        }

        this.setState({ submissionFailed: true })
      }
    }

    try {
      const response = await fetch([Constants.API_URL, 'submit'].join('/'), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.request)
      })

      if (response.ok) {
        const content = await response.json()
        this.userInfo.userId = this.backup_userInfo.userId = content.userId
        this.userInfo.userIP = this.backup_userInfo.userIP = content.userIP
        localStorage.setItem(Constants.APP_NAME + '_userInfo', JSON.stringify({ ...this.backup_userInfo }))

        this.setState({ submittedToAPI: true })
      } else {
        handleError()
      }
    } catch (err) {
      handleError()
    }

    this.makeCsv()
  }

  makeCsv () {
    console.log('this.userinfo', this.userInfo)
    if ('enteredScreenSizes' in this.userInfo) {
      delete this.userInfo.enteredScreenSizes
    }

    delete this.userInfo.screenSizeUnit

    Object.keys(this.experimentData).forEach(k => {
      this.experimentData[k] = { index: parseInt(k), ...this.experimentData[k] }
    })

    const formattedActivityRecord = []
    Object.keys(this.activityRecord).forEach(k => {
      this.activityRecord[k].forEach(v => {
        const row = {
          index: parseInt(k),
          ...v,
          duration: v.endTime ? (parseFloat(v.endTime) - parseFloat(v.startTime)) / 1000 : null
        }
        row.zoom = row.lastZoom
        delete row.lastZoom
        formattedActivityRecord.push(row)
      })
    })

    formattedActivityRecord.sort((a, b) => parseFloat(a.startTime) - parseFloat(b.startTime))

    const mergedData = [...Object.values(this.experimentData), ...formattedActivityRecord]

    const replacer = (key, value) => {
      if (value === null) { return '' } // specify how you want to handle null values here

      if (value instanceof Array) { return JSON.stringify(value) }

      return value
    }
    let header = []
    mergedData.forEach(row =>
      Object.keys(row).forEach(k => {
        if (!header.includes(k)) { header.push(k) }
      }))

    let csv = []
    if (this.userInfo) {
      const displaceBy = header.length
      header = header.concat(['uniqueHash'].concat(Object.keys(this.userInfo)))
      let infoRow = []
      for (let i = 0; i < displaceBy; i++) {
        infoRow.push('')
      }
      infoRow = infoRow.concat([this.uniqueHash].concat(Object.values(this.userInfo)))
      csv.push(infoRow.join(','))
    }

    csv = csv.concat(Object.values(mergedData).map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(',')))
    csv.unshift(header.join(','))
    csv = csv.join('\r\n')

    this.setState({ csv: csv, csvConstructed: this.config.settings.enable_csv_download })
  }

  copyHash () {
    this._hashContainer.current.select()
    this._hashContainer.current.setSelectionRange(0, 99999)

    document.execCommand('copy')
  }

  downloadCSV () {
    let link = document.createElement('a')
    link.download = 'output.csv'
    link.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.state.csv)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    link = null
  }

  // #region Enabling custom controls from config

  /**
   * Executes action associated with custom keys from the config
   * @param {Event} event The actual keyboard event received
   * @param {Object} keyMappings The mappings {keyCode, specialKey, ...options} from config
   * @param {string} op The operation performed by the key combinations ["zoom","next","previous",...,"toggle_size"]
   */
  addKeyAction (event, keyMappings, op) {
    keyMappings.forEach((map) => {
      if (map.specialKey) {
        if ((event.metaKey && map.specialKey === 'meta') || (event.ctrlKey && map.specialKey === 'ctrl') || (event.shiftKey && map.specialKey === 'shift')) {
          if (event.keyCode === map.keyCode) {
            event.preventDefault()
            this.action(op, map)
          }
        }
      } else {
        if (!event.metaKey && !event.ctrlKey && !event.shiftKey) {
          if (event.keyCode === map.keyCode) {
            event.preventDefault()
            this.action(op, map)
          }
        }
      }
    })
  }

  /**
   * Executes the action associated with the control type
   * @param {String} op The operation performed by the mouse event ["zoom","next","previous",...,"toggle_size"]
   */
  action (op) {
    switch (op) {
      case 'download_csv':
        this.downloadCSV()
        break
      default:
        break
    }
  }

  // #endregion Enabling custom controls from config
}

export default SubmitUI
