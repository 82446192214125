// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'

import React from 'react'
import ReactDOM from 'react-dom'
import { MemoryRouter, Route } from 'react-router-dom'
import './index.css'
import ExperimentUI from './ExperimentUI'
import * as serviceWorker from './serviceWorker'
import SubmitUI from './SubmitUI'
import StartUI from './StartUI'
import InvalidPage from './InvalidPage'
import ChromeInfoPage from './ChromeInfoPage'
import DeviceInfoPage from './DeviceInfoPage'

/*
* Simply handles routes
*/
ReactDOM.render(
  <React.StrictMode>
    <MemoryRouter>
      <Route path="/device" component={DeviceInfoPage}/>
      <Route path="/chrome" component={ChromeInfoPage}/>
      <Route path="/invalid" component={InvalidPage}/>
      <Route path="/experiment" component={ExperimentUI}/>
      <Route path="/submit" component={SubmitUI}/>
      <Route exact path="/" component={StartUI}/>
    </MemoryRouter>
  </React.StrictMode>,
  document.getElementById('wrapper')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
